.popup-active {
  height: 100%;
  overflow: hidden;
}

.popup-wrap-active {
  z-index: 10000;
  opacity: 1;
}

.popup-modal-active {
  transform: none;
  opacity: 1;
}

.popup-wrap {
  @include fixed(0);
  z-index: -1;
  opacity: 0;
  background: rgba(black, 0.9);
  transition: opacity 300ms ease;
  overflow: scroll;

  .close-bg {
    @include absolute(0);
  }

  .popup-modal {
    position: relative;
    z-index: 2;
    width: 90%;
    max-width: 35em;
    margin: 5% auto;
    border-top: 0.125em solid $secondary;
    transform: scale(0.5) translate3d(0, -2em, 0);
    opacity: 0;
    transition: all 300ms ease;
  } // .popup-modal

  .popup-cancel {
    text-align: center;
    padding: 0.5em;
    font-size: 0.8em;
    cursor: pointer;
    transition: all 300ms ease;

    p {
      margin: 0;
      text-transform: uppercase;
      letter-spacing: 0.1em;

      &:before,
      &:after {
        content: " - ";
      }
    }

    &:hover,
    &:focus {
      background: $secondary-light;
    }
  } // .popup-cancel

  @media (min-width: $screen-sm) {
    display: flex;
    align-items: center;
    padding: 0 0 5%;

    .popup-modal {
      margin: auto;
    }
  }
} //.popup-wrap

.popup-wrap-feedback {
  .popup-modal {
    position: relative;
  }

  input,
  textarea {
    background: $gray-lighter;

    &:focus {
      background: white;
    }
  }

  label {
    display: none;
  }

  .popup-cancel {
    @include absolute(-0.5em, -0.5em, auto, auto);
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    border: 2px solid white;
    color: white;
    text-align: center;
  }
} // .popup-wrap-feedback

.popup-wrap-search {
  display: flex;
  align-items: center;
  padding: 0 0 5%;

  .popup-modal {
    margin: auto;
    border: none;
    text-align: center;
  }

  .popup-cancel {
    p {
      color: white;
      transition: all 300ms ease;
    }

    &:hover,
    &:focus {
      background: transparent;
      p {
        color: $secondary-light;
      }
    }
  }

  h2 {
    font-size: 1em;
    color: white;
  }

  .input-wrap,
  input,
  button,
  .button {
    transition: all 300ms ease;
  }

  form {
    display: flex;
    align-items: center;
    border: 1px solid white;
    overflow: hidden;
    position: relative;
  }

  label {
    display: block;
    flex: 1 1 10em;
    margin: 0;
    position: relative;
    padding-right: 2.5em;
    overflow: hidden;

    span {
      display: none;
    }

    &:after {
      content: "\f002";
      font-family: "Font Awesome 5 Pro";
      color: white;
      position: absolute;
      font-size: 1.5em;
      right: 0.5em;
      bottom: 0.5em;
      cursor: pointer;
      padding-left: 0.5em;
    }

    input,
    input[type="search"] {
      @include field-placeholder-color(rgba(white, 0.5));
      color: white;
      margin: 0;
      border: none;
      outline: none;
      box-shadow: none;
      background: transparent;
    }

    input:-webkit-autofill {
      background-color: transparent !important;
    }
  }

  input[type="submit"].search-submit {
    @include absolute(0, 0, 0, auto);
    width: 4em;
    overflow: hidden;
    color: transparent;
    background: transparent;
    margin: 0;
    padding: 0;
    border: none;
    line-height: 1;
    box-shadow: none;
  }
} // .popup-wrap-search

.popup-active-feedback {
  @extend .popup-active;

  .popup-wrap-feedback {
    @extend .popup-wrap-active;

    .popup-modal {
      @extend .popup-modal-active;
    }
  }
} // popup-active-feedback

.popup-active-search {
  @extend .popup-active;

  .popup-wrap-search {
    @extend .popup-wrap-active;

    .popup-modal {
      @extend .popup-modal-active;
    }
  }
} // .popup-active-search

.fb-messenger-wrap {
  @include fixed(auto, calc(50% - 150px), 0, auto);
  width: 300px;
  z-index: 10000;
  transform: translate(0, 100%, 0);
  transition: transform 300ms ease;
  text-align: center;

  .fb-page {
    margin: auto;
  }

  .fb-messenger-close {
    @include absolute(-0.5em, -0.5em, auto, auto);
    z-index: 10000;
    font-size: 1.25em;
    color: white;
    background: $messenger;
    cursor: pointer;
    opacity: 0;
    transition: all 300ms ease;
    border-radius: 50%;
    width: 1.25em;
    height: 1.25em;
    padding: 0.1em 0 0;
    text-align: center;
  }

  @media (max-width: $screen-sm) {
    @include fixed(0);
    z-index: -1;
    overflow: scroll;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1.5);
    opacity: 0;

    .close-bg {
      @include absolute(0);
      transition: all 300ms ease;
    }

    .fb-page {
      @include flex-width(300px);
    }
  }

  @media (min-width: $screen-sm) {
    @include fixed(auto, 5%, 0, auto);
  }
} // .fb-messenger-wrap

.fb-messenger-active {
  .fb-messenger-wrap {
    transform: translate3d(0, 0, 0);

    .fb-messenger-close {
      opacity: 1;
    }
  } // .fb-messenger-wrap

  @media (max-width: $screen-sm) {
    height: 100%;
    overflow: hidden;

    .close-bg {
      background: rgba(black, 0.8);
    }

    .fb-messenger-wrap {
      z-index: 1100;
      opacity: 1;
      transform: none;

      .fb-messenger-close {
        @include absolute(1em, 1em, auto, auto);
      }
    }

    .fb-messenger-button {
      transform: translate3d(0, 200%, 0);
    }
  } // max-sm
} // .fb-messenger-active

.fb-messenger-button {
  @include fixed(auto, 1em, 1em, auto);
  cursor: pointer;
  z-index: 9999;
  transition: all 300ms ease;

  .fb-bar {
    display: none;
    background: $messenger;
    @extend .knockout;
    padding: 0.35em 1em;
    transition: all 300ms ease;

    p {
      margin: 0;
      color: white;
    }
  }

  .fa-stack {
    font-size: 2.5em;
  }
  .fa-stack-2x {
    color: $messenger;
    text-shadow: 0 2px 5px rgba(black, 0.3);
  }
  .fa-stack-1x {
    color: white;
  }

  @media (min-width: $screen-md) {
    .fa-stack {
      .fa-stack-1x {
        top: -0.125em;
      }

      &:after {
        content: "Message";
        font-family: $font;
        font-size: 0.2em;
        line-height: 1;
        color: white;
        text-transform: uppercase;
        display: block;
        @include absolute(auto, 0, 2em);
        text-align: center;
        z-index: 5;
      }
    }

    //   @include fixed(auto,5%,0,auto);
    //   width: 300px;
    //
    //   &:hover {
    //     transform: none;
    //   }
    //
    //   .fa-stack {
    //     display: none;
    //   }
    //
    //   .fb-bar {
    //     display: block;
    //     text-align: center;
    //     &:hover {
    //       background: lighten($messenger,10%);
    //     }
    //   }
    //
  } // min-md
} // .fb-messenger-button
