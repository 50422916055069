body {
  &.page.legal,
  &.page.sitemap,
  &.page-template-template-no-sidebar {
    .main {
      margin: auto;
      max-width: $noSidebarWidth;
    }
  }

  &.page-template-overview,
  &.page.the-latest,
  &.page.contact,
  &.page.hours-location,
  &.blog,
  &.home {
    .content {
      padding: 0;
      > .container {
        max-width: none;
        width: auto;
      }
    }
  }

  &.sidebar-primary {
    .sidebar {
      margin: 3em 0 0;
    }

    @media (min-width: $screen-sm) {
      .content {
        > .container {
          display: flex;
          flex-wrap: wrap;
        }
      }

      .main {
        flex: 1 1 10em;
        margin: 0;
      }

      .sidebar {
        @include flex-width(15em);
        margin: 0 0 0 3em;
      }
    } // min-sm

    @media (min-width: $screen-md) {
      .sidebar {
        @include flex-width(18em);
      }
    } // min-md

    @media (min-width: $screen-lg) {
      .sidebar {
        @include flex-width(23.125em);
      }
    }
  } // &.sidebar-primary
} // body

.section-sitemap {
  @extend .section;

  > ul {
    @extend .nolist;
    display: inline-block;
    margin-right: 2em;

    &.sitemap-descendants {
      margin: 0.5em 0 1em;
      display: block;
    }

    > li {
      border-bottom: 1px solid rgba(black, 0.1);

      > a {
        padding: 0.25em 0;
        display: block;
      }

      &:last-of-type {
        border-bottom: none;
      }

      ul {
        @extend .nolist;
        margin: 0.5em 0 0;

        li {
          a {
            color: $gray;
            position: relative;
            display: inline-block;
            padding-left: 1.25em;

            &:before {
              @include absolute(0.5em, auto, auto, 0.5em);
              content: "";
              border-top: 0.25em solid transparent;
              border-bottom: 0.25em solid transparent;
              border-left: 0.25em solid $secondary;
              transition: all 300ms ease;
            }

            &:hover {
              color: $primary;
            }
          }
        } //li

        @media (min-width: $screen-sm) {
          display: flex;
          flex-wrap: wrap;

          li {
            @include flex-width(calc(50% - 2em));
            margin-right: 2em;
          }
        } // min-sm

        @media (min-width: $screen-md) {
          li {
            @include flex-width(calc(33.33% - 2em));
          }
        } // min-sm
      } //ul
    } // >li
  } // >ul
} // .section-sitemap

.titlebar {
  h1,
  h2,
  h3,
  h4 {
    margin: 0 0 0.25em;
  }

  .subtitle {
    margin: 0 0 0.5em;
    font-size: 1em;
  }

  &.bg-primary {
    border: 0.125em solid white;
    border-width: 0.125em 0;
  }
}

.section-watermark {
  position: relative;

  .blurb,
  .container,
  .testimonial {
    position: relative;
    z-index: 3;
  }

  .bg-image {
    @include absolute(0);
    z-index: 1;
    opacity: 0.15;
    background-size: cover;
    background-position: center center;
  }
} //section-watermark

.contact-info-cols {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2em;

  .contact-col {
    margin: 1em 2em;
    flex: 1 1 15em;
    max-width: 15em;

    h4 {
      margin-top: 0;
    }
  }

  @media (min-width: $screen-sm) {
  }
} // .contact-info-cols

.section-split {
  h1,
  h2,
  h3 {
    margin-top: 0;
  }

  .subtitle {
    color: $gray-light;
  }

  .titlebar {
    margin: 0 0 1.5em;
  }

  .contact-info,
  .location-info {
    span {
      display: inline-block;
      margin: 0 0.75em 0 0;
    }
  }

  .social {
    margin: 0 -0.25em;

    li {
      a {
        padding: 0.25em;
      }
    }
  }

  .button {
    margin: 1em 0 0;
  }

  .split-col {
    position: relative;
  }
  .gmapcontact,
  .gmap {
    @include absolute(0);
    z-index: 5;
    width: 100%;
    height: 100%;
  }

  .split-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 56.25%;
  }

  .post-info {
    margin: 0 0 2em;
  }

  .split-image-secondary {
    margin-top: 3em;

    img {
      width: 100%;
    }
  }

  &.section-split-gutters {
    .split-image {
      padding: 0 0 3em;
    }
  }

  @media (min-width: $screen-sm) {
    display: flex;
    flex-wrap: wrap;

    .split-col {
      flex: 1 1 50%;
    }
    .split-10 {
      flex: 0 0 10%;
    }
    .split-20 {
      flex: 0 0 20%;
    }
    .split-30 {
      flex: 0 0 30%;
    }
    .split-40 {
      flex: 0 0 40%;
    }
    .split-50 {
      flex: 0 0 50%;
    }
    .split-60 {
      flex: 0 0 60%;
    }
    .split-70 {
      flex: 0 0 70%;
    }
    .split-80 {
      flex: 0 0 80%;
    }
    .split-90 {
      flex: 0 0 90%;
    }
    .split-100 {
      flex: 0 0 100%;
    }
    .content-left {
      padding-right: 1em;
    }
    .content-right {
      padding-left: 1em;
    }

    .split-image {
      padding-top: 0;
    }

    .container {
      width: 80%;
    }

    .split-image-secondary {
      margin-top: 0;
    }

    &.section-split-gutters {
      margin: 0 -1.5em;
      flex-wrap: wrap;

      .split-col {
        margin: 0 1.5em;
        flex: 1 1 calc(50% - 3em);
      }

      .split-image {
        padding: 0;
      }

      .split-image-event {
        flex: 1 1 calc(100% - 3em);
      }
    } // &.section-split-gutters

    &.align-center {
      align-items: center;

      .split-content {
        padding-bottom: 3em;
      }
    }
  } // min-sm

  @media (min-width: $screen-md) {
    &.section-split-gutters {
      .split-image-event {
        @include flex-width(10.625em);
        order: -1;
      }
    }
  }
} // .section-split

.section-alternating {
  .section-split:nth-child(odd) {
    @extend .bg-gray-lightest;
  }

  .section-grid:nth-child(odd) {
    @extend .bg-gray-lighter;
  }
} // .section-alternating

.section-overview {
  @media (min-width: $screen-sm) {
    .section-split.has-feat {
      &:nth-child(even) {
        .split-content {
          order: -1;
        }
      } // &nth-child even
    } // .section-split
  } // min-sm
} // .section-overview

.section-form {
  .form-columns {
    margin-top: 2em;
  }

  button {
    margin: 1em 0 0;
  }

  @media (min-width: $screen-sm) {
    .form-columns {
      display: flex;
      flex-wrap: wrap;
      margin: 2em -0.25em 0;
    }

    .form-col {
      @include flex-width(calc(50% - 0.5em));
      margin: 0 0.25em;
    }

    textarea {
      min-height: 16.0625em;
    }
  } // min-sm

  @media (min-width: $screen-md) {
    textarea {
      min-height: 9.375em;
    }
  }
} // .section-form

.post-meta {
  padding: 0 0 0.25em;
  margin: 0 0 1em;
  border-bottom: 1px solid rgba(black, 0.1);

  p {
    display: inline-block;
    margin: 0 1em 0.25em 0;

    i {
      margin-right: 0.25em;
    }

    &.post-location-info {
      display: inline-block;

      span:first-of-type {
        position: relative;

        &:before {
          content: "\f041";
          font-family: "FontAwesome";
          margin: 0 0.5em 0 0;
          // @include absolute(calc(50% - 0.5em), auto,auto,0);
        }
      }
    }
  }
}

.contact-page {
  .split-content {
    .overview-color {
      background-color: rgba(255, 255, 255, 0.4);
      height: 100%;
    }
    .section {
      position: relative;
      padding: 5em 0 0 0;
    }
    .content-contact {
      position: relative;
      h2 {
        text-transform: capitalize;
        margin: 0;
        font-size: 3.5em;
        font-weight: 400;
        font-family: $font;
        @media (max-width: $screen-sm) {
          font-size: 2.5em;
        }
      }
      p {
        width: 100%;
        font-weight: 100;
        font-size: 0.9em;
        color: black;
        letter-spacing: 0.2px;
        @media (max-width: $screen-sm) {
          width: 100%;
        }
      }
      .gform_wrapper {
        margin: 2em 0 0 0;
        form {
          input {
            margin: 0;
            padding: 10px 14px !important;
            border-color: $gray;
            font-family: $font2 !important;
          }
          textarea {
            padding: 10px 14px !important;
            border-color: $gray;
            font-family: $font2 !important;
          }
          li {
            margin: 0;
            &#field_2_3,
            &#field_2_2 {
              padding-right: 0;
            }
          }
          .top_label {
            margin: 0;
            padding: 0;
          }
          .gform_button {
            padding: 1.1em 1.3em !important;
            background: $primary;
            border-color: $primary;
            border-radius: 0.2em;
            font-size: 0.9em;
            letter-spacing: 1px;
            width: 14em;
            font-family: $font !important;
            &:hover {
              background: white;
              color: $primary;
            }
          }
        }
      }
    }
  }

  .contact-box {
    background: #1f1a16;
    margin-top: 2em;
    padding: 4.4em 0 6em 0;
    z-index: 5;
    .section-split {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .map-holder {
      margin-bottom: 1.3em;
      &:last-child {
        margin-bottom: 0;
        div.span {
          height: 100%;
        }
      }
      h4 {
        color: white;
        margin: 0 0 0.3em 0;
        text-transform: uppercase;
      }
      p.address {
        color: whitesmoke;
        font-weight: 100;
        font-size: 0.9em;
        text-align: left;
        margin: 0;
        line-height: 2em;
        padding-top: 0.4em;
        letter-spacing: 0.5px;
        &.hours {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: max-content;
          font-size: 1em;
          line-height: 1em;
          span {
            &:nth-child(1) {
              width: 5.5em;
            }
          }
        }
      }
      p.tel {
        margin: 0;
        line-height: 2em;
        display: flex;
        flex-direction: column;
        a {
          padding-top: 0.4em;
          color: whitesmoke;
          font-weight: 100;
          font-size: 0.9em;
          letter-spacing: 0.5px;
          &:hover {
            color: $primary;
          }
        }
      }
    }
    @media (max-width: $screen-sm) {
      width: 100%;
      padding: 1em 1em;
    }
  }
  @media (max-width: $screen-sm) {
    .split-image {
      height: 45vh;
    }
  }
  @media (min-width: $screen-sm) {
    .split-image {
      height: auto;
    }
  }
}

.section-heading {
  justify-content: space-between;
  padding: 1.5em 0;
  .split-subtitle {
    flex: 0 0 45%;
    h3 {
      text-transform: uppercase;
      font-size: 1.8em;
      font-weight: 900;
    }
  }
  .split-excerpt {
    flex: 0 0 45%;
    p {
      width: 100%;
      font-size: 0.9em;
      @media (min-width: $screen-sm) {
        font-size: 100%;
      }
      @media (min-width: $screen-lg) {
        width: 80%;
      }
    }
  }
  @media (min-width: $screen-sm) {
    padding: 4.5em 0;
    .split-subtitle {
      flex: 0 0 50%;
    }
    .split-excerpt {
      flex: 0 0 50%;
    }
  }
  @media (min-width: $screen-lg) {
    .split-subtitle {
      flex: 0 0 33.33%;
    }
    .split-excerpt {
      flex: 0 0 64.67%;
    }
  }
}

.promotions-page {
  .section-heading {
    padding: 0 0 1.5em 0;
    @media (min-width: $screen-sm) {
      padding: 2em 0;
    }
  }
  .split-subtitle h3 {
    font-size: 2em;
  }
  .section-details {
    margin-left: 1em;
    padding: 2.5em 0 0 0;
    border-top: 1px solid #979797;
    justify-content: space-between;
    padding: 1em;
    .split-details {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      a {
        margin-right: 1em;
        &:before {
          font-family: "Font Awesome 5 Pro";
          color: $primary;
          font-weight: 900;
          font-size: 1.6em;
        }
      }
      &:nth-child(1) {
        a:before {
          content: "\f3c5";
        }
      }
      &:nth-child(2) {
        a:before {
          content: "\f02c";
        }
      }
      &:nth-child(3) {
        a:before {
          content: "\f017";
        }
      }
      &:nth-child(4) {
        a:before {
          content: "\f3ed";
        }
      }
      p {
        margin: 0;
        font-size: 0.9em;
        letter-spacing: 0.3px;
        &.title {
          font-weight: bold;
        }
      }
      @media (min-width: $screen-sm) {
        width: 50%;
      }
      @media (min-width: $screen-md) {
        width: 33.33%;
      }
      @media (min-width: $screen-lg) {
        width: 22%;
      }
    }
  }
}

.promotions {
  .section-gallery {
    display: none;
  }
}

.repair {
  .section.content {
    padding: 0;
  }
}

.rentals,
.archive.tax-fastner_categories,
.archive.tax-fastener_categories,
.page-template-template-categories {
  .container {
    max-width: 90em;
  }
}
.rentals {
  .tile-inventory {
    @media (min-width: $screen-md) {
      height: 25em;
    }
    @media (min-width: $screen-lg) {
      height: 20em;
    }
  }
}

.error404 {
  .section-gallery {
    display: none;
  }
}

.woocommerce .woocommerce-ordering select {
  padding: 1em;
}

.woocommerce-result-count {
  margin: 0.4em;
}

.modal-reserve-container {
  display: flex;
  text-align: left;
  .modal-reserve {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 90%;
    max-width: 550px;
    z-index: 2000;
    visibility: hidden;
    backface-visibility: hidden;
    perspective: 1300px;
    img {
      max-width: 13em;
    }

    .modal__title {
      margin-top: 0.4em;
      margin-bottom: 0;
      text-align: left;
      font-size: 1.75em;
      @media (min-width: $screen-sm) {
        text-align: center;
        font-size: 2em;
      }
    }
    &--show {
      visibility: visible;
    }

    &--show ~ .overlay {
      opacity: 1;
      visibility: visible;
      z-index: 1001;
    }

    .modal__content {
      background: white;
      position: relative;
      border-radius: 3px;
      margin: 0 auto;
      padding: 14px;
      border-radius: 6px;
      box-shadow: 0 15px 100px -60px rgba(0, 0, 0, 0.7);

      transform-style: preserve-3d;
      transform: rotateX(-60deg);
      transform-origin: 50% 0;
      opacity: 0;
      transition: all 0.3s;
      .background-opacity {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
      .relative-mo {
        position: relative;
        z-index: 2;
      }
      a {
        color: #1f1a17;
        &:hover {
          color: $primary;
        }
      }
      .split-50 {
        @media (min-width: $screen-sm) {
          padding: 0 0 0 1em;
        }
      }
      @media (min-width: $screen-sm) {
        padding: 40px;
      }
    }

    &--show .modal__content {
      transform: rotateX(0deg);
      opacity: 1;
    }
    .btn--close {
      position: absolute;
      top: -1em;
      right: -1em;
      background-color: $primary;
      border: 1px solid $primary;
      border-radius: 50%;
      padding: 0.5em;
      height: 30px;
      width: 30px;
      &:hover {
        border-color: $primary;
        background-color: white;
        color: $primary;
      }
    }
  }
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
    background: rgba(0, 0, 0, 0.8);
    transition: all 0.3s;
  }
  button.reserve.btn {
    background-color: $primary;
    color: white;
    border: 2px solid $primary;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 400 !important;
    font-style: normal;
    border-radius: 0.4em;
    padding: 0.7em 1em;
    line-height: inherit;
    width: 14em;
    margin-top: 1em;
    &:hover {
      background-color: transparent;
      color: $primary;
      border-color: $primary;
    }
  }
}
