.home {
  .banner {
    .banner-image {
      height: 100%;
    }

    .slick-dots {
      li {
        border-color: white;

        &.slick-active {
          background: white;
        }
      }
    }
    .slick-arrow {
      display: flex !important;
      align-items: center;
      justify-content: center;
      height: auto;
      position: absolute;
      left: 3%;
      top: 50%;
      width: 50px;
      height: 50px;
      background: $primary;
      border-radius: 50%;
      transform: translate(0, -50%);
      z-index: 1;
      &.slick-next {
        right: 3%;
        left: auto;
      }
      i {
        padding: 0;
        color: white;
        font-weight: 500;
        font-size: 2em;
      }
      &.slick-disabled {
        cursor: not-allowed;
        i {
          color: #616060;
        }
      }
      @media (min-width: $screen-lg) {
        left: 5%;
        &.slick-next {
          right: 5%;
        }
      }
    }
  } // .banner

  @media (min-width: $screen-sm) {
    .banner {
      .banner-image {
        img {
          opacity: 0.5;
        }
      }

      &:not(.button) {
        text-transform: 0 2px 0.25em rgba(black, 0.3);
      }

      .overlay {
        justify-content: center;
      }
    }
  } // min-sm

  @media (min-width: $screen-md) {
    .banner {
      .slick-dots {
        @include absolute(auto, 0, 0.5em);
      }
    }
  }
  .section-promotion {
    margin-bottom: 1em;
    @media (min-width: $screen-sm) {
      flex-wrap: wrap;
      justify-content: space-between;
      .split-col {
        flex: 1 1 48.9%;
      }
    }
    @media (min-width: $screen-md) {
      margin-bottom: 3em;
      .split-col {
        flex: 1 1 48%;
      }
    }
    .split-promotion {
      @media (min-width: $screen-md) {
        margin-right: 2%;
      }
    }
    .split-content {
      position: relative;
      display: flex;
      padding: 2em 0;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(
        to right,
        rgba(#333333, 0.51),
        rgba(#333333, 0.91)
      );
      .overlay {
        position: absolute;
        background-size: cover;
        background-position: 50%;
        mix-blend-mode: multiply;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .content {
        position: relative;
        width: 100%;
        padding: 0 5%;
        margin: 0 auto;
        p,
        h2 {
          color: white;
        }
        h2 {
          text-transform: uppercase;
        }
        p {
          line-height: 22px;
          font-size: 17px;
          font-weight: 300;
        }
        @media (min-width: 1450px) {
          padding: 0 10%;
        }
        .section-split {
          flex-wrap: wrap;
          a {
            width: 12em;
            padding: 1em 0;
            &.button {
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: bolder;
              &.primary {
                padding: 1.4em 0;
                margin-right: auto;
                margin-left: auto;
              }
            }
            &.hassle {
              margin-right: auto;
              margin-left: auto;
              cursor: none;
            }
            @media (min-width: $screen-sm) {
              &.hassle {
                margin-right: 1em;
                letter-spacing: 0;
              }
            }
            @media (min-width: $screen-md) {
              width: 12vw;
              font-size: 0.8em;
              letter-spacing: 0;
            }
            @media (min-width: 1500px) {
              width: 12em;
              font-size: 0.9em;
              letter-spacing: 1px;
            }
          }
          @media (min-width: $screen-sm) {
            flex-wrap: nowrap;
          }
        }
      }
      @media (min-width: $screen-md) {
        margin-left: 2%;
      }
    }
  }
} // .home
