footer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  background-color: rgba(#413e3e, 1);
  .overlay {
    position: absolute;
    background-size: cover;
    background-position: 50%;
    mix-blend-mode: multiply;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.footer-top {
  position: relative;
  width: 100%;
  padding: 4em 0 1.3em 0;
  display: none;
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .logo-container {
      text-align: left;
      .brand-container {
        @include flex-width(15%);
        margin: 0;
      }
      .social {
        margin-top: 1.7em;
        li a:hover {
          color: $primary;
        }
      }
    }
    .nav-mid {
      width: 50%;
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 50%;
          padding-bottom: 1em;
          text-align: left;
          a {
            font-size: 0.75em;
            color: white;
            text-align: left;
            text-transform: uppercase;
            &:hover {
              color: $primary !important;
            }
          }
          @media (min-width: $screen-sm) {
            width: 50%;
          }
          @media (min-width: $screen-md) {
            width: 23%;
          }
        }
      }
    }
    .nav-container {
      width: 22%;
      @media (min-width: $screen-sm) {
        margin-top: 3em;
        width: 100%;
      }
      @media (min-width: $screen-md) {
        width: 22%;
        margin-top: 0;
      }
    }
  }
  .gform_wrapper {
    margin: 0;
  }
  #gform_1 {
    .gform_heading {
      position: relative;
      margin: 0;
      .gform_description {
        color: white;
        font-family: $font;
        font-size: 0.8em;
        width: 100%;
        position: absolute;
        bottom: 110%;
      }
    }
    #gform_fields_1 {
      position: relative;
    }
    .gform_footer {
      visibility: hidden !important;
      opacity: 0 !important;
      max-height: 0 !important;
    }
    li {
      margin: 0;
      padding: 0;
      max-width: 100% !important;
      .ginput_container {
        margin-top: 0;
      }
    }
    input {
      margin: 0;
      &#input_1_1 {
        padding: 12px 14px;
        font-size: 0.92em;
        background: white;
      }
    }
    #field_1_3 {
      position: absolute;
      right: 14px;
      top: 12px;
      width: fit-content;
      transform: translate(0, 0);
      border: 0;
      button {
        background: transparent;
        border: none;
        color: $gray;
        padding: 0;
        &:hover {
          color: $primary;
        }
      }
    }
  }
  @media (min-width: $screen-sm) {
    display: block;
  }
}
.footer-credits {
  text-align: center;
  position: relative;
  z-index: 1;
  padding: 1em 0;
  border-top: 2px solid #979797;
  width: 100%;

  .copyright {
    font-size: 0.8em;
    margin: 0 0 0.5em;

    p,
    ul,
    li {
      display: inline-block;
      margin: 0;
    }

    ul {
      margin: 0 0.5em;
    }

    li {
      margin: 0 0 0 0.5em;
      padding: 0 0 0 0.675em;
      line-height: 1;

      a {
        cursor: pointer;
      }

      &:first-of-type {
        margin: 0;
        padding: 0;
        border-width: 0;
      }
    }
    &.feedback {
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .social {
        margin-bottom: 1em;
      }
    }
  }

  @media (min-width: $screen-sm) {
    text-align: left;

    p {
      margin: 0;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: auto;
    }

    .jac-tech {
      padding: 0.25em 0 0;
    }
  } // min sm
} // .footer-credits
