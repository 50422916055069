.tile-cta {
  position: relative;
  background-color: #b92b29;

  h2,
  h3 {
    margin: 0 0 0.25em;
    text-transform: uppercase;
    font-weight: 700;
  }
  &.section {
    padding: 6em 0;
  }

  p {
    margin: 1.5em 0 1.2em 0;
  }
  .button {
    &.inverted {
      border-width: 3px;
      border-radius: 0.3em;
      width: 14em;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .tile-bg {
    @include absolute(0);
    background-size: cover;
    background-position: center center;
    mix-blend-mode: multiply;
  }
} // .tile-cta

.tile-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em;

  @media (min-width: $screen-sm) {
    margin: -1em;
  }
} // .tile-wrap

.ajax-button-container {
  @include flex-width(100%);
  text-align: center;
} //ajax-button-container

.tile {
  position: relative;
  @include flex-width(calc(100% - 1em));
  margin: 0.5em;
  padding: 0 0 4em;

  h2,
  h3 {
    margin: 0 0 0.25em;
  }

  .timestamp {
    @include absolute(0, auto, auto, 0);
    z-index: 5;
    padding: 0.5em 1.5em;

    p {
      margin: 0;
      font-size: 0.8em;
      font-weight: bold;
    }
  }

  .tile-buttons {
    @include absolute(auto, 0, 0);
    z-index: 3;
  }

  .button {
    margin: 0.5em 0.25em 0;
    font-size: 0.75em;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  .feat-placeholder {
    padding-top: $tileAspectRatio;
    position: relative;
    overflow: hidden;
    text-align: center;
    background-color: rgba(black, 0.9);

    &:before {
      content: "\f059";
      font-family: "FontAwesome";
      font-size: 35vw;
      color: rgba(white, 0.1);
      @include absolute(calc(50% - 0.5em), 0, auto);
    }
  } // .feat-placeholder

  &.has-terms {
    padding: 0 0 6.5em;

    .tile-buttons {
      @include absolute(auto, 0, 2.5em);
    }
  }

  @media (min-width: $screen-sm) {
    @include flex-width(calc(50% - 2em));
    margin: 1em;
    .feat-placeholder:before {
      font-size: 15vw;
    }
  } // min-sm

  @media (min-width: $screen-md) {
    @include flex-width(calc(33.33% - 2em));
    .feat-placeholder:before {
      font-size: 10vw;
    }
  } //min-md
} // .tile

.tile-promotion {
  position: relative;
  background-size: cover;
  background-position: center;
  @include flex-width(calc(100% - 1em));
  border: 2px solid #979797;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5em;
  transition: 0.4s all ease-in-out;
  background-color: transparent !important;
  .overlay {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    height: 20em;
    mix-blend-mode: normal !important;
    background: white;
    position: relative;
    &:before {
      background: rgba($primary, 0.75);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      content: "";
      opacity: 0;
      visibility: hidden;
      transition: 0.3s all ease-in-out;
    }
  }

  &:hover {
    transition: 0.4s all ease-in-out;
    border-color: rgba($primary, 0.85);
    .overlay {
      &:before {
        opacity: 1;
        visibility: visible;
        transition: 0.3s all ease-in-out;
      }
    }
    h3,
    p {
      color: white;
    }
  }
  h2,
  h3 {
    margin: 0 0 0.25em;
    font-size: 1.6em;
    text-transform: uppercase;
  }

  p {
    padding-top: 2em;
  }
  .entry-summary {
    text-align: center;
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 3.5em 0;
    .inner {
      padding: 1.5em 0.4em;
    }
  }
  @media (min-width: $screen-sm) {
    @include flex-width(calc(50% - 2em));
    h2,
    h3 {
      font-size: 1.4em;
    }
    .entry-summary {
      padding: 0;
    }
    margin: 1em;
  } // min-sm

  @media (min-width: $screen-md) {
    @include flex-width(calc(33.33% - 2em));
  } //min-md

  &.tile-resource {
    height: 20em;
    @media (min-width: $screen-sm) {
      height: 35vw;
    }
    @media (min-width: $screen-md) {
      height: 30vw;
      margin: 0.2em;
      @include flex-width(calc(33.33% - 0.4em));
    }
    @media (min-width: 1300px) {
      height: 18vw;
      @include flex-width(calc(25% - 0.4em));
    }
    h3 {
      color: white;
    }
    .overlay {
      background: rgba(black, 0.35);
    }
    &:hover {
      border-color: $primary;
      .overlay {
        background: rgba($primary, 0.7);
      }
    }
  }
} // .tile-promotion

.tile-inventory {
  border-width: 4px;
  border-color: $primary;
  position: relative;
  .overlay-link {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
    background: transparent;
    // &:hover {
    //   background-color: rgba($primary, 0.6);
    // }
  }
  .entry-summary {
    position: relative;
    z-index: 1;
    background-color: $primary;
    min-height: 6.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .inner {
      width: 100%;
    }
  }
  @media (min-width: $screen-sm) {
    @include flex-width(calc(50% - 0.4em));
    h2,
    h3 {
      font-size: 1.4em;
    }
    .entry-summary {
      padding: 0;
    }
    margin: 0.2em;
  } // min-sm

  @media (min-width: $screen-md) {
    @include flex-width(calc(33.33% - 0.4em));
    h2,
    h3 {
      font-size: 1.4em;
    }
  } //min-md
  @media (min-width: 1300px) {
    @include flex-width(calc(25% - 0.4em));
  } //min-md
}

.tile-repair {
  position: relative;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid $primary;
  border-top: 1px solid $primary;

  .col-div {
    @include flex-width(100%);
    @media (min-width: $screen-sm) {
      @include flex-width(50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .flex-container {
      display: flex;
      flex-wrap: wrap;
      a {
        margin-top: 1em;
      }
    }
  }
  .overlay {
    width: 100%;
    object-fit: contain;
    @media (min-width: $screen-sm) {
      height: 100%;
      min-height: 25em;
    }
    @media (min-width: $screen-md) {
      height: fit-content;
      min-height: 22em;
    }
  }
  // .overlay {
  // position: absolute;
  // background-size: cover;
  // background-position: center;
  // top: 0;
  // right: 0;
  // width: 100%;
  // height: 100%;
  // mix-blend-mode: multiply;
  // @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  // }
  //}
  // &:before {
  //   content: "";
  //   position: absolute;
  //   z-index: 3;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba($primary, 0.78);
  // }
  .entry-summary {
    position: relative;
    z-index: 4;
    // margin: 0 10%;
    padding: 0;
    display: flex;
    .inner {
      padding: 1.5em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      @media (min-width: $screen-sm) {
        width: 70%;
        padding: 2em;
      }
      @media (min-width: $screen-md) {
        width: 45%;
        padding: 2em;
      }
      @media (min-width: 1500px) {
        width: 35%;
      }
    }
    h3 {
      text-transform: uppercase;
      font-size: 2em;
      margin-bottom: 1em;
      color: $primary;
      margin: 0;
    }
    p {
      font-size: 0.95em;
      color: black;
    }
  }
  &:nth-child(even) {
    flex-direction: row-reverse;
    .overlay {
      right: auto;
      left: 0;
    }
    .entry-summary {
      justify-content: flex-end;
    }
  }
}
.tile-categories {
  @include absolute(calc(100% - 2.5em), 0, 0);
  z-index: 4;
  background: white;
  border-top: 1px solid $gray-lighter;
  overflow: hidden;
  text-align: left;
  padding: 0.5em 1.5em 0;
  transition: all 300ms ease;
  min-height: 2.5em;

  li {
    font-size: 0.625em;
  }

  a {
    color: $gray-light;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.125em;
    display: block;
    padding: 0.5em;

    i {
      color: $secondary;
      margin-right: 0.25em;
    }

    &:hover {
      color: $primary;
    }
  } // a

  li:last-of-type a {
    padding: 0.5em 0.5em 1em;
  }

  &:hover {
    top: auto;
  }
} // .tile-categories

.tile-post .feat-placeholder:before {
  content: "\f1ea";
}
.tile-page .feat-placeholder:before {
  content: "\f15b";
}
.tile-event .feat-placeholder:before {
  content: "\f073";
}

.search {
  .wrapper .row {
    .container {
      max-width: 100%;
      width: 100%;
      .section-grid {
        background: white;
      }
      .pagination-wrap .container,
      &.section {
        width: 90%;
        max-width: $maxContainerWidth;
      }
    }
  }
  .sidebar {
    display: none;
  }
  .pagination {
    p {
      color: #bbb;
    }
  }
}

.tile-lift {
  &:hover,
  &:focus {
    transform: scale(1.025) translate3d(0, 0, 0);
    box-shadow: -13px 11px 17px 1px rgba(0, 0, 0, 0.35);
    transition: 0.3s;
  }
  .entry-title {
    color: $primary;
  }
}

.tile-wrap-search {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em;
  justify-content: center;
  @include flex-width(100%);

  @media (min-width: $screen-sm) {
    margin: -1em;
  }
}

.tile-search {
  position: relative;
  @include flex-width(calc(100% - 1em));
  margin: 0.5em;
  transition: all 300ms ease-in-out;

  h2,
  h3 {
    margin: 0 0 0.25em;
  }

  .timestamp {
    @include absolute(0, auto, auto, 0);
    z-index: 5;
    padding: 0.5em 1.5em;

    p {
      margin: 0;
      font-size: 0.8em;
      font-weight: bold;
    }
  }

  .tile-buttons {
    @include absolute(auto, 0, 0);
    z-index: 3;
  }

  .button {
    margin: 0.5em 0.25em 0;
    font-size: 0.75em;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    height: 15em;
    object-fit: cover;
  }

  .entry-summary {
    text-align: center;
    h3 {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }
    .title {
      margin: 0.25em 0 0 0;
    }
  }

  .feat-placeholder {
    padding-top: $tileAspectRatio;
    position: relative;
    overflow: hidden;
    text-align: center;
    background-color: rgba(black, 0.9);

    &:before {
      content: "\f059";
      font-family: "font awesome 5 pro";
      font-size: 5em;
      color: rgba(white, 0.1);
      @include absolute(calc(50% - 0.5em), 0, auto);
    }
  } // .feat-placeholder

  &.has-terms {
    padding: 0 0 6.5em;

    .tile-buttons {
      @include absolute(auto, 0, 2.5em);
    }
  }

  @media (min-width: $screen-sm) {
    @include flex-width(calc(50% - 2em));
    margin: 1em;
    //  .feat-placeholder:before { font-size: 15vw; }
  } // min-sm

  @media (min-width: $screen-md) {
    @include flex-width(calc(33.33% - 2em));
    //  .feat-placeholder:before { font-size: 10vw; }
  } //min-md
} // .tile-search

// .select2,
// .select2-selection--single {
//   height: 100% !important;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   span {
//     &:focus {
//       outline: unset;
//     }
//   }
// }
// .select2-selection__rendered {
//   padding: 0.625em 0 0.625em 0;
//   width: 5em;
//   text-align: center;
// }
// .select2-dropdown {
//   width: max-content !important;
//   width: fit-content !important;
// }
// .select2-selection__arrow {
//   top: 50% !important;
//   transform: translate(0, -50%);
//   b {
//     top: 50% !important;
//   }
// }
// .pagination-topbar {
//   .filter-expand,
//   .search-wrap {
//     flex: unset !important;
//     width: 100%;
//     overflow: visible;
//   }
//   form {
//     display: flex;
//     padding: 0 !important;
//     border-left: 0;
//     input {
//       padding-top: 0;
//       padding-bottom: 0;
//     }
//     input[type="search"] {
//       width: 40rem;
//       padding-left: 2em;
//     }
//   }
// }

.pagination-resources {
  margin: 3em 0;
  .filter-expand {
    justify-content: center;
  }
  .pagination .filter-expand > div {
    flex: unset;
    width: 80%;
    input[type="search"] {
      padding: 0.5em 2em 0.5em 0;
    }
    @media (min-width: $screen-sm) {
      width: 40em;
    }
  }
}

body.promotions .promotions-page .tile-promotion {
  flex-direction: column;
  min-height: 85vw;
  transform: scale(1);
  transition: 0.3s all ease-in-out;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: white;
    transition: 0.3s all ease-in-out;
    visibility: visible;
    opacity: 1;
  }
  .entry-summary {
    visibility: visible;
    opacity: 1;
  }
  @media (min-width: $screen-sm) {
    padding: 5vw 0;
    min-height: 40.5vw;
  }
  @media (min-width: $screen-md) {
    padding: 5vw 0;
    min-height: 27vw;
  }
  @media (min-width: 1300px) {
    padding: 6vw 0;
    min-height: 22em;
  }
  @media (min-width: 1400px) {
    min-height: 24.8vw;
  }
  &:hover {
    transform: scale(1.07);
    transition: 0.3s all ease-in-out;
    .overlay {
      background-color: transparent;
      visibility: hidden;
      opacity: 0;
    }
    .entry-summary {
      visibility: hidden;
      opacity: 0;
      transition: 0.3s all ease-in-out;
    }
  }
}
