html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
} //html

h1,
h2,
h3,
h4,
h5 {
  color: $primary;
  // font-weight: 400;
  font-family: $font;
  line-height: 1.1em;
  margin: 1.5em 0 0.5em 0;
  padding: 0;
}

h1 {
  font-size: 1.75em;
  margin-top: 0;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.25em;
}
h4,
h5 {
  font-size: 1.15em;
}
.act-like-h1 {
  @extend h1;
}
.act-like-h2 {
  @extend h2;
}

.screen-reader-text {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media (min-width: $screen-sm) {
  h1 {
    font-size: 2.5em;
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.5em;
  }
  h4,
  h5 {
    font-size: 1.25em;
  }
}

p,
li,
td,
label {
  color: #1f1a17;
  font-family: $font2;
  font-weight: 300;
  line-height: 1.5em;
  margin: 0 0 0.8em;
  padding: 0;
}

p {
  &.subtitle {
    font-size: 0.875em;
    color: $gray-light;
    margin: 0 0 1em;
  }

  &.post-contact-info,
  &.contact-info {
    span {
      display: block;
    }
  }

  &.location-info,
  &.post-location-info {
    span {
      display: inline-block;
      margin: 0 0.5em 0 0;
    }
  }
} // p

a {
  color: $secondary;
  text-decoration: none;
  outline: none;
  transition: all 300ms ease;

  &:hover,
  &:focus {
    color: $secondary-dark;
    text-decoration: none;
  }
}

strong {
  font-weight: 700;
}
small {
  font-size: 0.8em;
}

li {
  margin: 0 0 0.5em;
  line-height: 1.5em;
}

ul,
ol {
  margin: 0 0 0.7em;
  padding: 0 0 0 1.2em;
  text-align: left;
}

.nolist {
  margin: 0;
  padding: 0;
  text-align: inherit;

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.social {
  margin: 0 -0.25em;

  li {
    display: inline-block;

    a {
      padding: 0.25em;
    }
  }
} // .social

hr {
  margin: 1.5em auto 2em;
  height: 0;
  border: none;
  border-top: 1px solid $gray-light;
}

blockquote {
  font-size: 1em;
  border: 0;
  width: auto;
  padding: 0.5em 5%;
  margin: 0;
  text-align: center;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 40%;
    height: 1px;
    margin: 1em auto;
    background: $gray-light;
  }

  &:after {
    margin: 1.25em auto 1.5em;
  }

  h2:first-of-type {
    margin-top: 0;
  }

  p {
    color: $primary;
    line-height: 1.35em;
    line-height: 1em;
    font-size: 1.5em;

    a {
      color: $primary;
    }
  }
}

svg {
  fill: currentColor;
}

img,
iframe {
  max-width: 100%;
  height: auto;
  border: none;
}

input,
textarea {
  color: $gray;
  background: white;
  font-weight: 400;
  font-size: 1em;
  font-family: $font;
  width: 100%;
  padding: 0.875em 1em;
  margin: 0 0 1em;
  border: 0;
  border-radius: 0.125em;
  border: 1px solid rgba(black, 0.1);
  outline: none;
  transition: all 500ms ease;
  @include field-placeholder-color($gray);

  &:focus {
    color: black;
    border-color: rgba(black, 0);
    box-shadow: 0 0 1em 0 rgba(black, 0.3);
  }
}

input:not([type="radio"]):not([type="checkbox"]) {
  appearance: none;
}

textarea {
  min-height: 10.375em;
}

.button,
button,
input[type="submit"] {
  // commonly changed properties
  font-family: $font;
  padding: 1em 2.5em;
  border-radius: 0.3em;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  font-weight: 400;
  text-transform: uppercase;
  // end commonly changed properties

  color: white;
  background: black;
  border-color: black;
  text-align: center;
  appearance: none;
  width: auto;
  cursor: pointer;
  display: inline-block;
  line-height: 1em;
  outline: none;
  border-width: 3px;
  border-style: solid;
  box-sizing: border-box;
  text-shadow: none;
  transition: all 300ms ease;

  &:hover,
  &:focus {
    background: $gray-dark;
    border-color: $gray-dark;
    color: white;
  }

  &.wide {
    padding-left: 3em;
    padding-right: 3em;
  }
} // .button

.button.inverted,
button.inverted,
input[type="submit"].inverted {
  background: transparent;
  border-color: white;
  color: white;
  width: 14em;
  font-weight: 500;
  font-family: $font;
  letter-spacing: 1px;

  &:hover,
  &:focus {
    background: white;
    border-color: white;
    color: $gray-dark;
  }
} // .button.alt, .button.primary

.button.primary,
button.primary,
input[type="submit"].primary {
  background: $primary;
  border-color: $primary;
  color: white;

  &:hover,
  &:focus {
    background: $primary-dark;
    border-color: $primary-dark;
    color: white;
  }
} // .button.alt, .button.primary

.button.primary.inverted,
button.primary.inverted,
input[type="submit"].primary.inverted {
  background: transparent;
  border-color: $primary;
  color: $primary;

  &:hover,
  &:focus {
    background: $primary;
    border-color: $primary;
    color: white;
  }
} // .button.alt, .button.primary

.button.alt,
.button.secondary,
button.alt,
button.secondary,
input[type="submit"].alt,
input[type="submit"].secondary {
  background: $secondary;
  border-color: $secondary;
  color: white;

  &:hover,
  &:focus {
    background: $secondary-dark;
    border-color: $secondary-dark;
    color: white;
  }
} // .button.alt, .button.secondary

.button.secondary.inverted,
button.secondary.inverted,
input[type="submit"].secondary.inverted,
.button.alt.inverted,
button.alt.inverted,
input[type="submit"].alt.inverted {
  background: transparent;
  border-color: $secondary;
  color: $secondary;

  &:hover,
  &:focus {
    background: $secondary;
    border-color: $secondary;
    color: white;
  }
} // .button.alt, .button.primary

.button.tertiary,
button.tertiary,
input[type="submit"].tertiary {
  background: $tertiary;
  border-color: $tertiary;
  color: white;

  &:hover,
  &:focus {
    background: $tertiary-dark;
    border-color: $tertiary-dark;
    color: white;
  }
} // .button.alt, .button.tertiary

.button.tertiary.inverted,
button.tertiary.inverted,
input[type="submit"].tertiary.inverted {
  background: transparent;
  border-color: $tertiary;
  color: $tertiary;

  &:hover,
  &:focus {
    background: $tertiary;
    border-color: $tertiary;
    color: white;
  }
} // .button.alt, .button.primary

.has-buttons {
  text-align: center;

  .button,
  button,
  input[type="submit"] {
    margin: 0.25em;
  }
}

.input-wrap.required,
.form-row.validate-required {
  position: relative;

  &:after {
    content: "*";
    top: 0.25em;
    right: 0.35em;
    position: absolute;
    color: red;
    font-size: 1em;
  }
}

input[type="radio"],
input[type="checkbox"] {
  width: auto;
  box-shadow: none;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  background: transparent;
}

.checkbox-wrap,
.radio-wrap {
  font-size: 0.8em;

  p {
    margin: 0;
  }

  label {
    display: inline-block;
    vertical-align: middle;
    margin: 0 1em 0 0.25em;
  }
}

select {
  width: 100%;
}

.select2-container {
  width: 100% !important;
  text-align: left;
  font-size: 1em;
  font-family: $font;
  font-weight: 300;
}

.select2-container--default {
  text-align: left;

  .select2-search--dropdown {
    .select2-search__field {
      margin: 0;
      box-shadow: none;
    }
  }

  .select2-selection--single {
    height: 3em;
    width: 100% !important;
    border-radius: 0.125em;
    border-color: rgba(black, 0.1);

    .select2-selection__arrow {
      width: 2em;
    }
    .select2-selection__arrow b {
      top: 1.375em;
    }

    .select2-selection__rendered {
      color: $gray;
      padding: 0.625em 1.5em 0.625em 1em;
    }
  }
}

fieldset {
  border-color: $gray-light;
  padding: 2em;
  margin: 1em auto;

  legend {
    color: $gray;
  }
}

.biggerer {
  font-size: 3em;
}
.bigger {
  font-size: 2em;
}
.big {
  font-size: 1.5em;
}
.small {
  font-size: 0.75em;
}
.smaller {
  font-size: 0.5em;
}
.smallerer {
  font-size: 0.25em;
}

/* !WordPress Core */

.alignnone,
a img.alignnone {
  margin: 0.3125em 1.25em 1.25em 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 0.3125em auto 0.3125em auto;
}

.alignright,
a img.alignright {
  float: right;
  margin: 0.3125em 0 1.25em 1.25em;
}

.alignleft,
a img.alignleft {
  float: left;
  margin: 0.3125em 1.25em 1.25em 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border-bottom: 2px solid #f0f0f0;
  max-width: 100%; /* Image does not overflow the content area */
  padding: 0 0 0.1875em;
  text-align: left;

  img {
    margin: 0 auto;
    padding: 0;
    min-width: 100%;
  }

  p.wp-caption-text {
    font-size: 0.8em;
    margin: 0;
    padding: 0.3125em 0.3125em 0.3125em;
    font-style: italic;
  }
}

@media screen {
  .knockout {
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: white;
    }
    p,
    li,
    td,
    label {
      color: white;
    }

    a {
      color: white;
      &:hover,
      &:focus {
        color: $secondary-light;
      }
    }

    p.subtitle {
      color: rgba(white, 0.7);
    }

    .button,
    button,
    input[type="submit"] {
      color: $primary;
      border-color: white;
      background: white;

      &:hover,
      &:focus {
        color: $primary;
        border-color: $gray-lighter;
        background: $gray-lighter;
      }
    }

    .button.inverted,
    button.inverted,
    input[type="submit"].inverted {
      color: white;
      border-color: white;
      background: transparent;

      &:hover,
      &:focus {
        color: $primary;
        border-color: white;
        background: white;
      }
    }

    .button.primary,
    button.primary,
    input[type="submit"].primary {
      color: white;
      border-color: $primary;
      background: $primary;

      &:hover,
      &:focus {
        color: white;
        border-color: $primary-light;
        background: $primary-light;
      }
    }

    .button.primary.inverted,
    button.primary.inverted,
    input[type="submit"].primary.inverted {
      color: $primary;
      border-color: $primary;
      background: transparent;

      &:hover,
      &:focus {
        color: white;
        border-color: $primary-light;
        background: $primary-light;
      }
    }

    .button.secondary,
    button.secondary,
    input[type="submit"].secondary .button.alt,
    button.alt,
    input[type="submit"].alt {
      color: white;
      border-color: $secondary;
      background: $secondary;

      &:hover,
      &:focus {
        color: white;
        background: $secondary-light;
        border-color: $secondary-light;
      }
    }

    .button.secondary.inverted,
    button.secondary.inverted,
    input[type="submit"].secondary.inverted,
    .button.alt.inverted,
    button.alt.inverted,
    input[type="submit"].alt.inverted {
      color: $secondary;
      border-color: $secondary;
      background: transparent;

      &:hover,
      &:focus {
        color: white;
        border-color: $secondary-light;
        background: $secondary-light;
      }
    }

    .button.tertiary,
    button.tertiary,
    input[type="submit"].tertiary {
      &:hover,
      &:focus {
        color: white;
        background: $tertiary-light;
        border-color: $tertiary-light;
      }
    }

    .button.tertiary.inverted,
    button.tertiary.inverted,
    input[type="submit"].tertiary.inverted {
      color: $tertiary;
      border-color: $tertiary;
      background: transparent;

      &:hover,
      &:focus {
        color: white;
        border-color: $tertiary-light;
        background: $tertiary-light;
      }
    }
  } // .knockout

  .bg-primary {
    .button.primary,
    button.primary,
    input[type="submit"].primary {
      color: $primary;
      border-color: white;
      background: white;

      &:hover,
      &:focus {
        color: $primary;
        border-color: rgba(white, 0.7);
        background: rgba(white, 0.7);
      }
    }

    .button.primary.inverted,
    button.primary.inverted,
    input[type="submit"].primary.inverted {
      color: white;
      border-color: white;
      background: transparent;

      &:hover,
      &:focus {
        color: $primary;
        border-color: white;
        background: white;
      }
    }
  } // .bg-primary

  .bg-secondary {
    .button.secondary,
    button.secondary,
    input[type="submit"].secondary {
      color: $secondary;
      border-color: white;
      background: white;

      &:hover,
      &:focus {
        color: $secondary;
        border-color: rgba(white, 0.7);
        background: rgba(white, 0.7);
      }
    }

    .button.secondary.inverted,
    button.secondary.inverted,
    input[type="submit"].secondary.inverted {
      color: white;
      border-color: white;
      background: transparent;

      &:hover,
      &:focus {
        color: $secondary;
        border-color: white;
        background: white;
      }
    }
  } // .bg-secondary

  .bg-tertiary {
    .button.tertiary,
    button.tertiary,
    input[type="submit"].tertiary {
      color: $tertiary;
      border-color: white;
      background: white;

      &:hover,
      &:focus {
        color: $tertiary;
        border-color: rgba(white, 0.7);
        background: rgba(white, 0.7);
      }
    }

    .button.tertiary.inverted,
    button.tertiary.inverted,
    input[type="submit"].tertiary.inverted {
      color: white;
      border-color: white;
      background: transparent;

      &:hover,
      &:focus {
        color: $tertiary;
        border-color: white;
        background: white;
      }
    }
  } // .bg-secondary
} // media screen

.lead-form {
  .select2-container--default .select2-selection--single {
    background: white;
  }

  .input-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: white;
    margin: 0 0 0.5em;
    border: 1px solid rgba(black, 0.1);
    position: relative;

    label {
      display: block;
      position: relative;
      order: -1;
      flex: 1 1 100%;
      padding: 0.5em 1.25em;
      margin: 0;
      font-size: 0.6em;
      color: $gray;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      transition: all 300ms ease;
      line-height: 1.2;
    }

    .select2-container--default {
      border: 1px solid rgba(black, 0.1);
      border-width: 1px 0 0;
      .select2-selection--single {
        border: none;
        border-radius: 0;
        height: 2.5em;

        .select2-selection__rendered {
          line-height: 1.5em;
        }
      }
    }

    textarea,
    input {
      border: 1px solid rgba(black, 0.1);
      border-width: 1px 0 0;
      background: white;
      margin: 0;
      padding: 0.75em 1em;
      display: block;
      flex: 1 1 100%;
      box-shadow: none;
      border-radius: 0;

      &:focus + label {
        color: $secondary;
      }
    }

    .parsley-errors-list {
      @include absolute(calc(100% - 0.125em), 0, auto);
      z-index: 10;
      opacity: 0;
      @extend .nolist;
      @extend .knockout;
      min-height: 0.125em;
      max-height: 0.125em;
      overflow: hidden;
      flex: 1 1 100%;
      font-size: 0.75em;
      transition: all 500ms ease;

      li {
        display: inline-block;
        margin: 0 1em 0 0;
        padding: 0.5em;
      }
    }

    &:hover,
    &:focus {
      .parsley-errors-list {
        max-height: 5em;
      }
    }

    &.parsley-error,
    &.parsley-success {
      label {
        color: $error;

        &:before {
          font-family: "FontAwesome";
          color: $error;
          @include absolute(calc(50% - 0.5em), 0.5em, auto, auto);
          line-height: 1;
          font-size: 1.25em;
        }
      } // label
    } // error/success

    &.parsley-error {
      label {
        color: $error;

        &:before {
          content: "\f05a";
          color: $error;
        }
      } // label

      input {
        border-color: rgba($error, 0.3);
      }

      .parsley-errors-list {
        opacity: 1;
        background: $error;
      }
    } // &.parsley-error

    &.parsley-success {
      label {
        color: $success;

        &:before {
          content: "\f058";
          color: $success;
        }
      } // label

      input {
        border-color: rgba($success, 0.3);
      }

      .parsley-errors-list {
        opacity: 1;
        background: $success;
      }
    } // &.parsley-success
  } // .input-wrap

  .checkbox-wrap {
    font-size: 1em;

    label {
      position: relative;
      padding: 0 0 0 1.5em;
      line-height: 1.2;

      &:before {
        content: "\f096";
        font-family: "FontAwesome";
        color: $gray-light;
        @include absolute(0, auto, auto, 0);
      }
    } // label

    input {
      display: none;

      &:checked + label {
        color: $primary;

        &:before {
          content: "\f14a";
          color: $secondary;
        }
      }
    } // input
  } // .checkbox-wrap

  @media (min-width: $screen-xs) {
    .checkbox-grid {
      display: flex;
      flex-wrap: wrap;
      margin: -0.25em;
    }

    .checkbox-wrap {
      margin: 0.25em;
      @include flex-width(calc(50% - 0.5em));
    }
  }

  @media (min-width: $screen-sm) {
    .checkbox-wrap {
      margin: 0.25em;
      @include flex-width(calc(33.33% - 0.5em));
    }

    .fields {
      margin: -0.5em;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      .input-wrap,
      .input-wrap.full {
        @include flex-width(calc(100% - 0.5em));
        margin: 0.25em;
      }

      .input-wrap.quarter {
        flex: 1 1 calc(50% - 0.5em);
      }
    } // .fields
  }

  @media (min-width: $screen-md) {
    .input-wrap {
      &:not(.textarea-wrap) {
        label {
          @include flex-width(10em);
        }
      }

      .select2-container--default,
      input {
        flex: 1 1 5em;
        border-width: 0 0 0 1px;
      }
    } // .input-wrap

    .textarea-wrap {
      textarea {
        flex: 1 1 100%;
      }
      label {
        padding: 1.5em 1.25em;
      }
    } // .textarea-wrap

    .fields {
      .input-wrap.third {
        flex: 1 1 calc(50% - 0.5em);
      }

      .input-wrap.quarter {
        flex: 1 1 calc(33.33% - 0.5em);
      }
    } // .fields
  } // min-md

  @media (min-width: $screen-lg) {
    .fields {
      .input-wrap.half {
        flex: 1 1 calc(50% - 0.5em);
      }

      .input-wrap.third {
        flex: 1 1 calc(33.33% - 0.5em);
      }

      .input-wrap.quarter {
        flex: 1 1 calc(25% - 0.5em);
      }
    } // .fieldset
  } // min-lg
} // .lead-form

.checkbox-errors {
  text-align: center;

  ul {
    @extend .nolist;

    li {
      color: $error;
      margin: 0.5em 0 0;
    }
  }
}
