.woocommerce-page {
  .button.inverted {
    background: transparent;
    width: 14em;
    border: 3px solid #000;
    border-color: #fff;
    color: #fff;
    padding: 1em 2.5em;
    border-radius: 0.3em;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    font-weight: 400;
    &:hover {
      background: #fff;
      border-color: #fff;
      color: #313131;
    }
  }
  &.account {
    .woocommerce {
      display: flex;
      flex-wrap: wrap;
    }

    .modal {
      flex: 1 1 100%;
    }

    .woocommerce-MyAccount-content,
    .woocommerce-MyAccount-navigation {
      flex: 1 1 100%;
    }

    .woocommerce-MyAccount-content {
      order: -1;
    }

    .woocommerce-MyAccount-navigation {
      margin: 2em 0 0;
    }

    @media (min-width: $screen-sm) {
      .woocommerce-MyAccount-navigation {
        @include flex-width(12em);
        margin: 0 2em 0 0;
      }

      .woocommerce-MyAccount-content {
        order: 0;
        flex: 1 1 10em;
      }
    } // min-sm

    @media (min-width: $screen-sm) {
      .woocommerce-MyAccount-navigation {
        @include flex-width(17em);
      }
    } // min-sm

    @media (min-width: $screen-md) {
      // .woocommerce-MyAccount-navigation {
      //   @include flex-width(20em);
      // }
    } // min-md

    @media (min-width: $screen-lg) {
      // .woocommerce-MyAccount-navigation {
      //   @include flex-width(23.125em);
      // }
    } // min-lg
  } // &.account

  &.single-product {
    .content-right,
    .single-image {
      @media (min-width: $screen-sm) {
        flex: 0 0 45%;
        width: 45%;
      }
    }
    .content-right {
      @media (min-width: $screen-sm) {
        padding-left: 5%;
      }
    }
  }
} // .woocommerce-page

.woocommerce-MyAccount-content {
  // @extend .inner;
  // @extend .bg-white;
  // border: 1px solid rgba(black,0.1);
}

.woocommerce-MyAccount-navigation {
  // @extend .bg-white;
  // margin: 3em 0 0;
  // border: 1px solid rgba(black,0.1);

  ul {
    @extend .bg-white;
    list-style: none;
    padding: 0;
    margin: 0 0 1.5em;
    border: 1px solid rgba(black, 0.1);

    &:before {
      content: "Account Navigation";
      @extend .bg-primary;
      @extend .inner;
      color: white;
      font-family: $font;
      font-weight: 600;
      display: block;
      text-transform: uppercase;
    }

    li {
      padding: 0;
      margin: 0;

      a {
        display: block;
        padding: 0.5em 1.5em;
        border: 1px solid $gray-lighter;
        border-width: 0 0 1px;
        color: $gray-light;
      }

      &.woocommerce-MyAccount-navigation-link--downloads {
        display: none;
      }

      &:last-of-type {
        a {
          border-width: 0;
        }
      }

      &:hover {
        a {
          color: $secondary;
        }
      }

      &.is-active {
        a {
          background: $secondary;
          color: white;
          border-color: $secondary;
        }
      } // &.is-active
    } // li
  } // ul
} //.woocommerce-MyAccount-navigation

.woocommerce-message {
  @extend .container;
  @extend .section;
  text-align: center;
  font-family: $font;
  color: $gray;
  border-bottom: 1px solid $gray-light;

  .button {
    @extend .secondary;
    margin: 0 auto 1em;
    display: block;
    text-align: center;
    max-width: 20em;

    &:before {
      content: "\f07a";
      font-family: "FontAwesome";
      margin: 0 0.25em 0 0;
      font-size: 0.875em;
      transition: all 300ms ease;
    }
  }

  @media (min-width: $screen-sm) {
    .button {
      display: inline-block;
      margin: 0 2em 0 0;
    }
  }
} // .woocommerce-message

.woocommerce-breadcrumb {
  @extend p;
  font-family: $font;
  margin: 0 0 1em;
}

.woocommerce {
  width: 100%;
  font-family: $font;

  .cart-collaterals {
    .cart_totals {
      float: none;
      width: 100%;
      margin: 1em 0 0;

      h2 {
        display: none;
      }

      .wc-proceed-to-checkout {
        margin-top: 1em;
      }

      @media (min-width: $screen-xs) {
        .wc-proceed-to-checkout {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;

          &:before,
          &:after {
            content: none;
          }

          a {
            @include flex-width(50%);
            margin: 0;
            max-width: 20em;
            text-align: center;
            padding-left: 0;
            padding-right: 0;
          }
        }
      } // min xs

      @media (min-width: $screen-sm) {
        .cart-totals {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        .order-details {
          @include flex-width(16em);
        }

        .shipping {
          border: none;
          flex: 1 1 10em;
          margin: 0 2em 0 0;

          h4 {
            margin-top: 0;
          }
        }

        .wc-cart-shipping-notice {
          text-align: center;
          flex: 1 1 100%;
          padding: 0.5em 0;
          margin: 1.5em 0;
          border: 1px solid rgba(black, 0.1);
          border-width: 1px 0;
        }

        .wc-proceed-to-checkout {
          @include flex-width(100%);
        }
      } // min-sm
    }
  }

  .checkout {
    margin: 1em 0 0;
  }

  .coupon,
  .checkout_coupon {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 2em;

    label {
      flex: 1 1 100%;
      display: none;
    }

    input {
      margin: 0;
    }

    input[type="text"] {
      flex: 1 1 10em;
    }

    input[type="submit"] {
      padding: 1em 0;
      text-align: center;
      @include flex-width(2em);
      border-radius: 0;
      background: $secondary;
      border-color: $secondary;
    }
  }

  .login {
    // label {
    //   display: none;
    // }

    label.rememberme-label {
      display: inline-block;
    }

    input {
      margin: 0;
    }

    input#rememberme {
      margin: 0 0.25em 0 0.5em;
    }

    // .lost_password {
    //   padding: 1em 0 0;
    //   margin: 1em 0 0;
    //   border-top: 1px solid $gray-light;
    // }
  }

  .checkout-tab {
    border-bottom: 1px solid $gray-light;
    padding: 1em 0;

    a {
      display: block;
    }

    form {
      margin: 1.5em 0 3em;
      position: relative;

      .close {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }

      p {
        padding-right: 2em;
        flex: 1 1 100%;

        &.form-row {
          padding-right: 0;
        }
      }

      &.checkout_coupon {
        p,
        .close {
          display: none;
        }
      }
    }

    .woocommerce-info {
      font-family: $font;
      padding-left: 3em;
      position: relative;

      &:before {
        font-family: "FontAwesome";
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 2.25em;
      }
    }

    @media (min-width: $screen-sm) {
      float: left;
      width: 45%;

      form.login,
      form.checkout_coupon {
        position: fixed;
        z-index: 1000;
        top: 15vw;
        left: 50%;
        margin-left: -10em;
        width: 20em;
        background: white;
        padding: 1.5em;
        box-shadow: 0 0 2.5em rgba(black, 0.3);

        .close {
          top: 0.5em;
          right: 0.5em;
        }

        &.checkout_coupon {
          p,
          .close {
            display: block;
          }

          .close {
            top: 1.5em;
            right: 1.5em;
          }
        }
      }
    } // min-sm

    @media (min-width: $screen-md) {
      width: 35%;
    }

    @media (min-width: $screen-lg) {
      width: 25%;
    }
  } // .checkout-tab

  .checkout-tab-login {
    @media (min-width: $screen-sm) {
      float: right;
    }

    .woocommerce-info:before {
      content: "\f084";
    }
  }

  .checkout-tab-coupon {
    .woocommerce-info:before {
      content: "\f0c4";
    }

    @media (min-width: $screen-sm) {
      float: left;
    }
  }

  .variation {
    font-size: 0.75em;
    margin: 0;
    dt,
    dd {
      display: inline-block;
      margin: 0.125em;

      p {
        margin: 0;
      }
    }
  }

  .variations {
    label {
      display: none;
    }
  }
} // .woocommerce

.woocom-row {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;

  li {
    display: block;
  }

  @media (min-width: $screen-sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    li {
      text-align: center;

      &:first-of-type {
        text-align: left;
      }
      &:last-of-type {
        text-align: right;
      }
    }

    .product-remove {
      @include flex-width(1em);
    }

    .product-thumbnail {
      @include flex-width(5em);
      margin: 0 1em;
    }

    .product-name {
      flex: 1 1 10em;
      text-align: left;
      margin: 0;
    }

    .product-price,
    .product-quantity,
    .product-subtotal {
      @include flex-width(5em);
    }
  }
} // .woocom-row

.my_account_orders {
  margin: 0 0 2em;
}

.woocom-row.order {
  text-align: left;
  li {
    padding: 0.5em 0 0;
    margin: 0.5em 0 0;
    border-top: 1px solid $gray-light;

    &:before {
      text-transform: uppercase;
      font-size: 0.8em;
      color: $gray;
      margin-right: 0.5em;
    }

    &.order-number:before {
      content: "Order";
    }
    &.order-total:before {
      content: "Total:";
    }

    &.order-status {
      @extend a;
      // font-weight: 600;
      text-transform: uppercase;
    }

    &.order-actions {
      margin-left: -0.1875em;
      margin-right: -0.1875em;

      a {
        margin: 0.25em;
        padding: 0.375em 0.5em;
        font-size: 0.75em;
      }
    }
  }

  @media (min-width: $screen-sm) {
    text-align: center;
    border-top: 1px solid $gray-light;
    padding: 0.5em 0 0;
    margin: 0.5em 0 0;

    &:last-of-type {
      border-bottom: 1px solid $gray-light;
      padding: 0.5em 0;
      margin: 0.5em 0;
    }

    li {
      margin: 0;
      padding: 0;
      border: none;
      @include flex-width(20%);

      &:before {
        content: none;
      }

      &.order-number {
        @include flex-width(4em);
      }

      &.order-number,
      &.order-total {
        &:before {
          content: none;
        }
      }

      &.order-status {
        text-align: center !important;
      }

      &.order-recurring {
        @include flex-width(100%);
        margin: 0.5em 0 0;
      }
    }
  }
} //.woocom-row.order

.woocom-row.order_item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &:last-of-type {
    margin: 0 0 0.5em;
  }

  li {
    flex: 1 1 10em;
    text-align: left;

    &:nth-child(even) {
      text-align: right;
      @include flex-width(10em);
    }
  }
}

.order_details,
.customer_details {
  .woocom-row {
    text-align: left;
  }
}

.order_details {
  .product-name {
    a {
      display: block;
    }
  }
}

.col2-set.addresses {
  margin: 3em 0 1em;

  address {
    @extend p;
  }

  header {
    position: relative;
    padding-right: 3em;
    border-bottom: 1px solid $gray-light;
    margin: 0 0 0.5em;
    padding: 0 0 0.5em;

    h3 {
      margin: 0;
    }

    a {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  @media (min-width: $screen-sm) {
    display: flex;
    justify-content: space-between;

    div {
      @include flex-width(45%);
    }
  }
} // .col2-set.addresses

.woocom-row-head.order-head {
  @media (min-width: $screen-sm) {
    li {
      @include flex-width(20%);
    }
  }
}

.woocom-row-head {
  display: none;

  li {
    text-transform: uppercase;
    color: $gray;

    &.product-price,
    &.product-quantity,
    &.product-subtotal {
      margin: 0 0 0 1em;
    }

    span {
      font-size: 0.8em;
    }
  }

  @media (min-width: $screen-sm) {
    display: flex;
  }
}

.woocom-row-foot {
  @media (min-width: $screen-sm) {
    padding: 1em 0;
    border-top: 1px solid $gray-light;

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      input {
        margin: 0;
      }
    }

    .coupon {
      margin: 0;
      @include flex-width(20em);
    }
  }
}

.cart_item {
  border: 1px solid $gray-light;
  padding: 3.5em 1.5em 1.5em;
  margin: 0 0 1.5em;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .product-remove,
  .product-thumbnail,
  .product-name,
  .product-subtotal {
    flex: 1 1 100%;
  }

  .product-price,
  .product-quantity {
    @include flex-width(50%);
    margin: 0;
    padding: 0.5em 0;

    &:before {
      font-size: 0.65em;
    }
  }

  .product-remove {
    position: absolute;
    background: $secondary;
    overflow: hidden;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    text-align: right;
    transition: all 300ms ease;

    a.remove {
      line-height: 0.925;
      display: block;
      width: auto;
      height: auto;
      border-radius: 0;
      padding: 0.125em 0.5em 0.25em;
      text-align: right;
      color: white !important;

      &:before {
        content: "Remove";
        font-size: 0.6em;
        font-weight: normal;
        text-transform: uppercase;
        color: rgba(white, 0.5);
        margin: 0 0.5em 0.25em 0;
        display: inline-block;
        vertical-align: middle;
        transition: all 300ms ease;
      }

      &:hover,
      &:focus {
        &:before {
          color: white;
        }
      }
    }
  } // .product-remove

  .product-thumbnail {
    a {
      display: block;
      margin: auto;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .product-name {
    font-weight: 600;
    color: $secondary;
    margin: 0.5em 1.5em;
    text-transform: uppercase;
    line-height: 1.2;
    text-align: center;
  } // .product-name

  .product-price,
  .product-quantity,
  .product-subtotal {
    &:before {
      text-transform: uppercase;
      font-size: 0.8em;
      color: $gray;
      margin-right: 0.5em;
    }
  }

  .product-price {
    text-align: left;

    &:before {
      content: "Price:";
    }
  } // .product-price

  .product-quantity {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &:before {
      display: block;
      @include flex-width(1em);
      margin: 0 0.5em 0 0;
    }

    .quantity {
      display: block;
      flex: 1 1 3em;

      input {
        margin: 0;
        padding: 0.5em;
        border-width: 0 0 1px;
        text-align: center;
      }
    }
    &:before {
      content: "x";
    }
  }

  .product-subtotal {
    border-top: 1px solid $gray-light;
    padding: 1em 0 0;
    margin: 1em 0 0;

    &:before {
      content: "Subtotal:";
    }
  }

  @media (min-width: $screen-sm) {
    padding: 1em 0;
    border-width: 1px 0 0;
    margin: 0;

    li {
      padding: 0;
    }

    .product-remove {
      @include flex-width(1em);
    }
    .product-thumbnail {
      @include flex-width(5em);
    }
    .product-price {
      @include flex-width(5em);
    }
    .product-quantity {
      @include flex-width(5em);
    }
    .product-subtotal {
      @include flex-width(5em);
    }

    .product-remove {
      position: static;
      background: transparent;

      a.remove {
        color: $gray !important;
        padding: 0;
        text-align: left;

        &:before {
          content: none;
        }
      }
    }

    .product-thumbnail {
      margin: 0 1em;
    }

    .product-name {
      text-align: left;
      flex: 1 1 10em;
      max-width: none;
      width: auto;
      margin: auto;
    }

    .product-subtotal {
      border-top: none;
      padding: 0;
    }

    .product-price,
    .product-quantity,
    .product-subtotal {
      margin: 0 0 0 1em;
      &:before {
        content: none;
      }
    }
  }
} // .cart_item

.woocommerce-cart {
  .wc-proceed-to-checkout {
    text-align: center;
    margin: 0;
    padding: 0;

    .button {
      display: block;
      margin: 0.25em auto;
      font-size: 0.8em;
    }
  }
}

.cart-totals-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $gray-light;
  border-width: 0 0 1px;
  margin: 0;
  padding: 0.5em 0;

  span {
    flex: 1 1 40%;
    text-align: left;

    &:first-of-type {
    }

    &:last-of-type {
      text-align: right;
      font-weight: bold;
      color: $gray-darker;
    }
  }
} // .cart-totals-row

.cart-totals-row.order-total {
  border: none;
}

.cart-discount {
  .woocommerce-remove-coupon {
    font-size: 0;
    display: inline-block;
    vertical-align: middle;

    &:after {
      content: "\f057";
      font-family: "FontAwesome";
      font-size: 18px;
      display: inline-block;
      vertical-align: middle;
      line-height: 1.5;
    }
  }
} // .cart-discount

.cart-totals-row.shipping {
  display: block;

  #shipping_method {
    @extend .nolist;
    margin: 0 0 1em;
  }

  span {
    flex: none;

    &:last-of-type {
      text-align: left;
    }
  }

  @media (min-width: $screen-sm) {
    .shipping-calculator-form {
      position: fixed;
      z-index: 1000;
      top: 15vw;
      left: 50%;
      margin-left: -10em;
      width: 20em;
      background: white;
      padding: 1.5em;
      box-shadow: 0 0 2.5em rgba(black, 0.1);

      .close {
        cursor: pointer;
      }
    }
  } // min-sm
} // .cart-totals-rwo.shipping

.woocommerce-billing-fields {
  h3 {
    margin-top: 0;
  }
}

.woocommerce-billing-fields,
.woocommerce-shipping-fields {
  #ship-to-different-address {
    label {
      cursor: pointer;
      @extend a;
    }

    input {
      display: none;
    }
  }

  .form-row {
    input {
      margin: 0;
    }

    label {
      display: none;

      &.checkbox {
        display: inline-block;
        margin: 0;
        vertical-align: middle;
      }
    }
  }

  div.create-account {
    p {
      font-size: 0.8em;

      &.form-row {
        font-size: 1em;
      }
    }
  }

  @media (min-width: $screen-xs) {
    h3 {
      width: 98%;
      margin-left: 1%;
      margin-right: 1%;
      float: left;
    }

    .form-row {
      width: 48%;
      float: left;
      margin-left: 1%;
      margin-right: 1%;
    }

    .form-row-wide {
      width: 98%;
    }

    .notes {
      float: none;
      width: 98%;
    }

    p.create-account {
      width: 98%;
      margin-left: 1%;
      margin-right: 1%;
    }

    div.create-account {
      p {
        float: right;
        width: 48%;
        font-size: 0.7em;

        &.form-row {
          float: left;
          font-size: 1em;
        }
      }
    }
  } // min xs

  @media (min-width: $screen-lg) {
    h3 {
      width: 99%;
      margin-left: 0.5%;
      margin-right: 0.5%;
    }

    .form-row {
      width: 24%;
      margin-left: 0.5%;
      margin-right: 0.5%;
    }

    .form-row-wide {
      width: 49%;
    }

    .notes {
      width: 99%;
    }

    div.create-account {
      p {
        font-size: 0.9em;

        &.form-row {
          font-size: 1em;
        }
      }
    }
  }
} // woocommerce billing & shipping fields

.woocommerce-checkout {
  width: 100%;

  .cart_item {
    .product-quantity {
      &:before {
        content: none;
      }
    }

    .product-name,
    .product-total {
      font-size: 1em;
      margin: 0.5em 0;

      span {
        margin: 0;
      }
    }
  }

  .payment_methods {
    @extend .nolist;
  }

  #order_review_heading {
    display: none;
  }

  @media (min-width: $screen-sm) {
    padding: 0;
    margin: 0;

    .col2-set {
      float: left;
      width: 50%;
    }

    .woocommerce-checkout-review-order {
      float: left;
      width: 45%;
      margin: 0 0 0 5%;
    }
  }

  @media (min-width: $screen-md) {
    .col2-set {
      width: 60%;
    }

    .woocommerce-checkout-review-order {
      width: 35%;
    }
  }

  @media (min-width: $screen-lg) {
    .col2-set {
      width: 70%;
    }

    .woocommerce-checkout-review-order {
      width: 25%;
    }
  }
}

.woocommerce-error {
  @include flex-width(100%);
  @extend .knockout;
  list-style: none;
  background: $secondary;
  padding: 0;
  margin: 0 0 2em;

  a {
    display: inline-block;
  }

  li {
    padding: 0.5em 1em;
    margin: 0;
    text-align: center;
  }

  @media (min-width: $screen-xs) {
    display: flex;
    flex-wrap: wrap;

    li {
      flex: 1 1 10em;
      font-size: 0.7em;
      border: 1px solid rgba(white, 0.1);
      padding: 0.5em 1em;
      color: rgba(white, 0.5);

      strong {
        font-size: 1.25em;
        display: block;
        color: white;
      }
    }
  }
}

.woocommerce-checkout-payment {
  .payment_box {
    margin: 1em 0 0;
  }

  .payment_method_stripe {
    label,
    img,
    input {
      display: inline-block;
      vertical-align: middle;
    }

    input,
    label {
      margin: 0;
    }
  }
}

.place-order {
  margin: 1em 0 0;

  .button {
    margin: 0;
    vertical-align: middle;
  }

  .back-to-cart {
    padding: 0.5em;
    font-size: 1.3em;
    line-height: 1;
  }
}

.woocommerce-orders {
  @media (min-width: $screen-sm) {
    .woocom-row {
      flex-wrap: wrap;

      li {
        &.order-number {
          @include flex-width(4em);
        }

        &.order-date {
          @include flex-width(7em);
          text-align: right;
        }

        &.order-status {
          @include flex-width(7em);
          order: 2;
          text-align: left !important;
        }

        &.order-total {
          max-width: none;
          width: auto;
          flex: 1 1 100%;
          text-align: left;
        }

        &.order-actions {
          max-width: none;
          width: auto;
          flex: 1 1 10em;
          order: 3;
          text-align: right;
        }

        &.order-recurring {
          order: 4;
        }
      } //li
    } // woocom-row.order
  } // min-sm

  @media (min-width: $screen-lg) {
    .woocom-row {
      li {
        &.order-actions,
        &.order-status {
          order: 0;
          text-align: center !important;
        }

        &.order-actions {
          flex: 1 1 5em;
        }

        &.order-date {
          text-align: center;
        }

        &.order-total {
          flex: 1 1 5em;
          text-align: center;
        }

        &.order-recurring {
          @include flex-width(10em);
          margin: auto;
          text-align: left;
        }
      }
    }
  } // min-lg

  @media (max-width: $screen-lg-min) {
    .woocom-row-head {
      display: none;
    }
  } // max-lg-min
} // .woocommerce-orders

.recurring-selector {
  display: flex;

  .select-wrap {
    flex: 1 1 5em;
  }

  button {
    @include flex-width(3em);
    padding: 0.25em 0;
    text-align: center;
    margin: 0 0 0 0.25em;
    position: relative;

    i {
      font-size: 2em;
      @include absolute(calc(50% - 0.5em), 0.45em, auto, 0);
      text-align: right;
    }
  }

  .select2-container--default {
    .select2-selection--single {
      background: $gray;
      border-color: rgba(white, 0.5);

      .select2-selection__arrow b {
        border-color: white transparent transparent;
      }

      .select2-selection__rendered {
        color: white;
      }
    }

    &.select2-container--open {
      .select2-selection--single {
        .select2-selection__arrow b {
          border-color: transparent transparent white;
        }
      }
    }
  }
}

.woocommerce-edit-address .woocommerce-MyAccount-content form,
.woocommerce-EditAccountForm.edit-account {
  @extend .inner;
  @extend .bg-white;
  border: 1px solid rgba(black, 0.1);

  h3:first-of-type {
    margin-top: 0;
  }
}

.recurring-radio {
  margin: 0 1% 1.5em;

  h3 {
    margin-left: 0 !important;
  }

  .form-row {
    width: 100%;
    margin: 0 0 1.5em;
    float: none;

    label {
      font-size: 1em;
      display: inline-block;
      padding: 0 0 0 1.5em;
      margin: 0 1.5em 0 0;
      position: relative;
      transition: all 300ms ease;
      cursor: pointer;
      color: $gray-light;

      &:before {
        content: "\f1db";
        font-family: "FontAwesome";
        @include absolute(0, auto, auto, 0);
        color: $gray-light;
        transition: all 300ms ease;
      }
    } // label

    input {
      display: none;

      &:checked + label {
        color: $gray;

        &:before {
          content: "\f058";
          color: $secondary;
        }
      }
    }
  }
}

.order-conditions {
  width: 100%;
  margin: 0 0 2em;
}

/* WooCommerce 3.0 Gallery */

.woocommerce-product-gallery {
  position: relative;
  margin-bottom: -0.25em -0.25em 3em -0.25em;
  display: block;
  overflow: hidden;
}

.woocommerce-product-gallery figure {
  margin: 0;
}

.woocommerce-product-gallery
  .woocommerce-product-gallery__image:nth-child(n + 2) {
  @include flex-width(calc(20% - 0.5em));
  margin: 0.25em 0.25em 0em 0.25em;
  display: inline-block;
}

.woocommerce-product-gallery .flex-control-thumbs li {
  list-style: none;
  float: left;
  cursor: pointer;
}

.woocommerce-product-gallery .flex-control-thumbs img {
  opacity: 0.5;
}

.woocommerce-product-gallery .flex-control-thumbs img.flex-active,
.woocommerce-product-gallery .flex-control-thumbs img:hover {
  opacity: 1;
}

.woocommerce-product-gallery img {
  display: block;
  @include flex-width(100%);
}

.woocommerce-product-gallery--columns-3 .flex-control-thumbs li {
  width: 33.3333%;
}

.flex-viewport {
  margin: 0 1.5%;
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.1);
}

ol.flex-control-nav {
  padding-left: 0;
}
.woocommerce-product-gallery--columns-4 .flex-control-thumbs li {
  width: 31%;
  margin: 2% 1%;
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.1);
}

.woocommerce-product-gallery--columns-5 .flex-control-thumbs li {
  width: 20%;
}

.woocommerce-product-gallery__trigger {
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 99;
}

a.woocommerce-product-gallery__trigger {
  text-decoration: none;
}

.single-product
  div.product
  .woocommerce-product-gallery
  .woocommerce-product-gallery__trigger {
  position: absolute;
  top: 0.875em;
  right: 0.875em;
  display: block;
  height: 2em;
  width: 2em;
  border-radius: 3px;
  z-index: 99;
  text-align: center;
  text-indent: -999px;
  overflow: hidden;
}

.single-product
  div.product
  .woocommerce-product-gallery
  .woocommerce-product-gallery__trigger {
  background-color: $secondary;
  color: #ffffff;
}

.single-product
  div.product
  .woocommerce-product-gallery
  .woocommerce-product-gallery__trigger:hover {
  background-color: $secondary;
  border-color: $secondary;
  color: #ffffff;
}

.single-product
  div.product
  .woocommerce-product-gallery
  .woocommerce-product-gallery__trigger:before {
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  content: "\f00e";
  line-height: 2;
  text-indent: 0;
}

.pswp__top-bar {
  &:after {
    content: "\f057";
    font-family: "Font Awesome 5 Pro";
    color: white;
    position: absolute;
    top: 0.5em;
    right: 1em;
    font-size: 20px;
  }
  .pswp__button {
    display: none !important;
  }
} //pswp__top-bar

.woocommerce-product-gallery__wrapper,
.woocommerce-product-gallery__wrapper img {
  @include flex-width(100%);
}

ul.products {
  @media (min-width: $screen-sm) {
    display: flex;
    flex-wrap: wrap;
  }
  li.product {
    border: 1px solid #aaaaaa;
    text-align: center;
    padding: 1em !important;
    width: 100% !important;
    transition: 0.7s all ease-in-out;
    .woocommerce-loop-product__title {
      color: black;
      text-transform: uppercase;
      font-family: $font2;
    }
    a.button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: unset;
      opacity: 0;
    }
    a img {
      padding: 0 3em;
    }
    @media (min-width: $screen-sm) {
      width: 32.33% !important;
      margin-right: 1% !important;
      padding: 2em !important;
      a img {
        padding: 0 1em;
      }
    }
    @media (min-width: $screen-md) {
      width: 24% !important;
      margin-right: 1% !important;
      padding: 2em !important;
      a img {
        padding: 0 1em;
      }
    }
    &:hover {
      transform: scale(1.02);
      transition: 0.4s all ease-in-out;
    }
  }
}
.single-product {
  .woocommerce-notices-wrapper {
    display: none;
  }
  .gallery-video {
    a:after {
      opacity: 1;
      transform: scale(1);
    }
  }
  .form-bar {
    background-color: #1f1a17;
    padding: 1em 1em;
    margin: 2em 0;
    .gform_confirmation_message {
      color: white;
      font-family: $font;
      text-align: center;
    }
    form {
      .validation_error {
        width: 100%;
      }
      .gform_fields {
        @media (min-width: $screen-sm) {
          display: grid;
          grid-template-columns: auto auto auto auto auto auto;
          grid-gap: 10px;
        }
        & > li {
          text-align: center;
          padding: 0 !important;
        }
        textarea,
        input,
        li {
          margin: 6px 0 0 0 !important;
          @media (min-width: $screen-md) {
            margin: 0 !important;
          }
        }
        .ginput_container {
          height: 100%;
          margin: 0 !important;
        }
        textarea,
        input {
          padding: 10px 10px !important;
          width: 100% !important;
          max-width: 100% !important;
          height: 100%;
          font-size: 0.9em !important;
          @media (min-width: $screen-md) {
            text-align: center;
          }
        }
        .ginput_container_textarea,
        textarea {
          min-height: 100%;
          height: 5em;
          text-align: left;
        }
        #field_3_2 {
          grid-area: 2 / 1 / span 1 / span 1;
        }
        #field_3_5 {
          grid-area: 2 / 2 / span 1 / span 1;
        }
        #field_3_8 {
          grid-area: 2 / 3 / span 1 / span 4;
          @media (min-width: $screen-lg) {
            grid-area: 2 / 3 / span 1 / span 1;
          }
        }
        #field_3_6 {
          grid-area: 1 / 3 / span 1 / span 4;
          @media (min-width: $screen-lg) {
            grid-area: unset;
          }
        }
        #field_3_9 {
          grid-area: 3/1 / span 2 / span 6;
          @media (min-width: $screen-lg) {
            grid-area: 1 / 5 / span 2 / span 2;
          }
        }
        #field_3_9,
        #field_3_8,
        #field_3_5,
        #field_3_2,
        #field_3_6 {
          max-width: 100% !important;
        }
      }

      .gform_footer.top_label {
        text-align: center;
        input {
          width: 13em;
          margin-left: auto;
          margin-right: auto;
          height: 100%;
          background: $primary;
          color: white;
          &:hover {
            background: white;
            color: $primary;
          }
          @media (min-width: $screen-sm) {
            width: 100%;
          }
        }
        @media (min-width: $screen-md) {
          padding: 0 0 0 1em;
          margin: 0;
        }
      }
      @media (min-width: $screen-md) {
        display: flex;
        flex-wrap: wrap;
        .gform_body {
          width: 80%;
        }
        .gform_footer.top_label {
          width: 20%;
        }
      }
    }
    @media (min-width: $screen-md) {
      padding: 1.5em 3em;
    }
  }
  .product {
    a.button {
      padding: 1em 2.5em;
      border-radius: 0.3em;
      font-size: 0.8em;
      letter-spacing: 0.1em;
      font-weight: 400;
      text-transform: uppercase;
      color: $primary;
      background: transparent;
      border: 2px solid $primary;
      &:hover {
        background: $primary;
        color: white;
      }
    }
  }
  .flex-videos,
  .related {
    h2 {
      font-size: 1.15em;
      border-bottom: 1px solid $gray-light;
      color: black;
      text-transform: uppercase;
      padding-bottom: 0.9em;
      margin-bottom: 0.9em;
    }
  }
  .related .products h2 {
    border-bottom: 0;
  }
  li.product {
    text-align: center;
  }
  .product_title {
    font-size: 1.8em;
    width: 100%;
    color: black;
    text-transform: uppercase;
  }
  .product_meta {
    display: none;
  }
  .woocommerce-product-details__short-description {
    p,
    li {
      font-size: 0.9em;
      font-family: $font;
    }
  }
  .storeBack {
    color: $primary;
  }
  .content-sidebar {
    margin-top: 1em;
  }
  .gform_wrapper {
    li {
      margin: 0;
      textarea,
      input {
        margin: 0;
        padding: 14px 10px;
      }
    }
  }
}
