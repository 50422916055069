.media-gallery-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: -0.5em;
}

.gallery-item {
  @include flex-width(calc(100% - 1em));
  margin: 0.5em;

  img {
    display: block;
    width: 100%;
  }

  a {
    display: block;
    position: relative;
    //margin: 0.5em;
    overflow: hidden;

    &:before,
    &:after {
      transition: all 300ms ease;
    }

    &:before {
      content: "";
      background: rgba(black, 0.3);
      position: absolute;
      @include absolute(0);
      opacity: 0;
    }

    &:after {
      content: "\f055";
      font-family: "Font Awesome 5 Pro";
      font-size: 2em;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -0.5em 0 0 -0.5em;
      opacity: 0;
      transform: scale(3);
      text-shadow: 0 0.125em 0.25em rgba(black, 0.5);
    }

    &:hover,
    &:focus {
      &:before,
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  @media (min-width: $screen-xs) {
    @include flex-width(calc(50% - 1em));
  }

  @media (min-width: $screen-md) {
    @include flex-width(30.7%);
    margin-bottom: 3%;
  }
} // .gallery-iitem

.gallery-video {
  a:after {
    content: "\f144";
  }
}
.gallery-image {
  a:after {
    content: "\f055";
  }
}
