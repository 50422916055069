.wrapper {
  transition: all 300ms ease; // needed for off-canvas nav

  // top padding should be equivalent
  // to the height of the fixed menu
  // this will likely change per breakpoint
  // so be sure to adjust whenever needed

  padding-top: 5em;

  @media (min-width: $screen-sm) {
    padding-top: 7.5em;
  }
  // @media (min-width: $screen-md) {
  //   padding-top: 8.3em;
  // }
} // wrapper

.menu-off-canvas.left {
  // add class to menu-off-canvas to change the side it appears from
  left: 0;
  right: auto;
  transform: translate3d(-$offCanvasWidth, 0, 0);
} // .menu-off-canvas.left

.menu-off-canvas {
  // $offCanvasWidth defined in _variables.scss
  // feel free to adjust if needed
  .container {
    width: 100%;
  }
  img {
    margin: 0 auto;
  }
  @include fixed(0, 0, 0, auto);
  z-index: -1;
  width: $offCanvasWidth;
  height: 100%;
  overflow: scroll;
  transition: all 300ms ease;
  box-shadow: inset 0.25em 0 1em rgba(black, 0.5);
  transform: translate3d($offCanvasWidth, 0, 0);
  * {
    padding: 0;
    margin: 0;
  }
  .container {
    padding: 3em 2em;
    a:not(.has-sub) {
      text-decoration: none;
      display: block;
      padding: 10px 0 0 0;
      text-transform: uppercase;
      font-size: 0.8em;
    }
    .menu-item {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-bottom: 1px dotted lightgray;
      &:hover {
        background: #fef150;
        i,
        a {
          color: black;
        }
        padding-left: 8px;
        -webkit-transition: all 1.5s ease-in-out;
        transition: all 0.7s ease-in-out;
      }
    }
    .menu-primary {
      margin-top: 3em;
      ul {
        display: none;
        transition: all 1.5s ease-in-out;
        width: 100%;
      }

      .tap {
        display: block;
        transition: all 1.5s ease-in-out;
      }

      li {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 1px dotted lightgray;
        &:hover {
          background: $primary;
          i,
          a {
            color: black;
          }
          -webkit-transition: all 1.5s ease-in-out;
          transition: all 0.7s ease-in-out;
        }
      }
      .has-sub {
        .other {
          transition: all 1.5s ease-in-out;
          background: $primary;
          .dropdown-link {
            border-bottom: 1px dotted lightgray;
            padding-left: 12px;
            a {
              text-decoration: none;
              padding: 5px 0 5px 0px;
              text-transform: uppercase;
              font-size: 0.6em;
              color: white;
            }
            i {
              color: white;
              padding-right: 5px;
            }
            &:hover {
              i {
                color: black;
              }
              a {
                color: black;
                -webkit-transition: all 1.5s linear;
                transition: all 0.7s linear;
              }
            }
          }
        }
        &:hover {
          .title {
            transition: all 0.7s ease-in-out;
            padding-left: 8px;
          }
          .drop-arrow i {
            transition: all 1s ease-in-out;
            color: black;
          }
        }
      }

      .drop-arrow {
        transition: all 0.5s ease-in-out;
        float: right;
        margin-right: 9px;
        padding-top: 5px;
        transform: rotate(90deg);
        i {
          font-size: 1.3em;
        }
      }

      .turn-arrow {
        transition: all 0.5s ease-in-out;
        transform: rotate(180deg);
      }
    }
    .social {
      border-bottom: 1px dotted lightgray;
      display: flex;
      flex-wrap: wrap;
      li {
        list-style: none;
        margin-right: 20px;
        a {
          padding: 10px 0 10px 0px;
        }
        &:hover {
          i,
          a {
            color: black;
          }
          -webkit-transition: all 1.5s ease-in-out;
          transition: all 0.7s ease-in-out;
        }
      }
    }
  }
} // .menu-off-canvas

.searchsettings {
  fieldset legend {
    width: 100%;
    text-align: center;
  }
}

.announcement-bar {
  text-align: center;
  marquee,
  p {
    margin: 0;
    color: white;
  }
}

.menu-fixed {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.8s all ease-in-out;
  box-shadow: 0 0 0.25em rgba(black, 0.6);
  input[type="search"],
  #ajaxsearchlite1 {
    @media (min-width: $screen-sm) {
      display: none;
    }
    @media (min-width: $screen-md) {
      display: block;
    }
    .prosettings,
    .promagnifier {
      height: 100% !important;
    }
    .probox,
    div.asl_w .probox {
      height: 40px !important;
    }
  }
  #ajaxsearchlite2 {
    .prosettings,
    .promagnifier {
      height: 100% !important;
    }
    height: 3em;
    width: 24em;
    margin-right: 1em;
    display: none;
    .probox {
      height: 100% !important;
      form {
        height: 100% !important;
      }
      input {
        color: black !important;
        height: 100% !important;
      }
    }

    input::-ms-input-placeholder,
    input:-ms-input-placeholder,
    input::placeholder {
      color: red !important;
      opacity: 1; /* Firefox */
    }

    @media (min-width: $screen-sm) {
      display: block;
    }
    @media (min-width: $screen-md) {
      display: none;
    }
  }
  .menu-other {
    width: 30em;
  }
  .topbar,
  .bottombar {
    .flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menu-primary,
      .menu-secondary,
      .social,
      .menu-other {
        display: flex;
        align-items: center;
        .social a {
          color: $primary-dark;
          font-size: 1.5em;
          margin-right: 0.6em;
          &:hover {
            color: $secondary;
          }
        }
        // a,
        // p,
        // li,
        // span {
        //   // font-size: 12px;
        //   // font-weight: 600;
        //   // letter-spacing: 1px;
        // }
      } // menus

      .menu-primary,
      .menu-secondary,
      .menu-other {
        justify-content: flex-end;
        .menu-search {
          margin-left: 1em;
          @include flex-width(35px);
          border-left: 1px solid white;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            color: white;
            font-size: 1.3em;
            font-weight: 400;
          }
        }
      } //menu-primary & menu-seconcondary
    } // container
  } // topbar & bottombar

  .topbar {
    padding: 0.5em 0;
    position: relative;
    max-height: auto;
    transition: 0.5s all ease-in-out;
    border-bottom: 1px solid #d7d7d760;
    .container {
      z-index: 2;
      justify-content: flex-end;
      .brand {
        margin-left: 0;
        @include flex-width(20%);
        img {
          transition: 0.5s all ease-in-out;
          @media (min-width: $screen-sm) {
            height: 5.2em;
          }
        }

        @media (max-width: $screen-md) {
          @include flex-width(150px);
        } // .brand
      }
      .menu-other {
        .contactDiv {
          background-color: $secondary;
          padding: 0 2em;
          height: 3.7em;
          display: flex;
          align-items: center;
          margin-right: 0.6em;
          text-align: center;
          p {
            color: white;
            margin: 5px 0 0 0;
            font-weight: 300;
            text-transform: uppercase;
            font-size: 0.6em;
          }
          &#contactPhone,
          &#contactHours {
            i {
              color: white;
              &:after,
              &:before {
                color: white;
                opacity: 1;
              }
            }
            &:hover {
              background: $gray-dark;
              cursor: pointer;
              transition: 0.1s all ease-in-out;
              i:after {
                background: $secondary;
                border-radius: 50%;
              }
            }
          }
          &#contactHours {
            &:hover {
              i:before {
                background: $secondary;
                border-radius: 50%;
              }
            }
          }
          &#bookAppointment {
            background: $primary-dark;
            padding: 0 0.9em;
            transition: 0.2s all ease-in-out;
            margin-right: 0;
            p {
              font-size: 0.8em;
              margin: 0;
            }
            &:hover {
              background: $secondary;
              cursor: pointer;
              transition: 0.1s all ease-in-out;
            }
          }
        }
      }
    }
    @media (max-width: $screen-md) {
      display: none !important;
    }
  } // topbar

  .bottombar {
    padding: 0;
    position: relative;
    transition: 0.5s all ease-in-out;
    .brand {
      @media (min-width: $screen-md) {
        display: none;
      }
      margin-left: 0;
      @include flex-width(20%);
      img {
        transition: 0.5s all ease-in-out;
      }

      @media (max-width: $screen-md) {
        @include flex-width(150px);
      } // .brand
    }
    @media (max-width: $screen-md) {
      .menu-primary {
        display: none !important;
      }
      .menu-utility {
        display: block;
      }
    }

    @media (max-width: $screen-sm) {
      background-color: $primary-dark;
    }
  } //bottombar

  .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .menu-primary,
    .menu-secondary,
    .social,
    .menu-other {
      display: flex;
      align-items: center;

      a,
      p,
      li,
      span {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    } // menus

    .menu-primary,
    .menu-secondary,
    .menu-other {
      justify-content: flex-end;
      .menu-search {
        margin-left: 1em;
        @include flex-width(35px);
        border-left: 1px solid white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          color: white;
          font-size: 1.3em;
          font-weight: 400;
        }
      }
    } //menu-primary & menu-seconcondary

    .menu-primary {
      position: relative;
      padding: 0;
      margin: 0;
      li {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2em;
        margin-bottom: 2em;
        cursor: pointer;
        a {
          // display: table-cell;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          vertical-align: middle;
          padding: 0;
          margin-top: 0.9em;
          font-size: 1.1em;
          font-family: $font;
          color: #a1a1a1;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0.2px;
          transition: 0.5s all ease-in-out;
        }
        &:last-child {
          ul.nolist {
            display: flex;
            flex-wrap: wrap;
            li {
              width: 50%;
            }
          }
        }
        @media (min-width: 1000px) {
          margin-left: 1em;
        }
        @media (min-width: 1170px) {
          margin-left: 3em;
        }
        .active a {
          color: $primary !important;
        }
      }
      a {
        &:hover,
        &:focus {
          color: $primary;
        }
      } //a
      li.active a.toptitle {
        color: $primary !important;
      }
    } //menu-primary

    .menu-secondary {
      li {
        margin-left: 1.5em;
      }
    } //menu-contact & menu-secondary
  } // container

  .menu-utility {
    display: none;
    position: relative;
  }
  @media (max-width: $screen-sm) {
    justify-content: space-between;
    .menu-left {
      display: none !important;
    }
    .menu-utility {
      display: block;
      padding: 1.8em 0;
    }
  }
  @media (min-width: $screen-sm) {
    .menu-utility {
      padding: 3em 0;
      display: none;
    }
  }
} //menu-fixed

.menu-fixed-slide {
  position: fixed;
  z-index: 1001;
  top: -100%;
  left: 0;
  width: 100%;
  transition: 0.8s all ease-in-out;
  box-shadow: 0 0 0.25em rgba(black, 0.1);
  .brand {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .flex-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .holder-search {
      text-align: right;
    }
    .topbar {
      width: 95%;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      .menu-topbar {
        li.active {
          a {
            color: $primary;
            font-weight: 400;
            &:hover {
              color: $secondary;
            }
          }
        }
        a {
          padding: 0;
          font-size: 0.7em;
          margin-right: 10px;
          &:hover {
            color: $primary;
          }
        }
      }
      .menu-search {
        font-size: 0.8em;
        margin-top: 0.2em;
        i {
          cursor: pointer;
          &:hover {
            color: $primary;
          }
        }
      }
    }
    .menu-secondary {
      margin-top: 10px;
      a {
        background: $primary;
        font-family: $font;
        font-weight: 400 !important;
        font-style: normal;
        border-radius: 0.4em;
        padding: 0.7em 1em;
        width: 14em;
        text-align: center;
        border: 2px solid $primary;
        color: white;
        &:hover {
          background: white;
          color: $primary;
        }
      }
      li.active {
        a {
          background: $gray-darker;
          border-color: $gray-darker;
          color: white;
          &:hover {
            background: white;
            color: $gray-darker;
            border-color: $gray-darker;
          }
        }
      }
    }
  }
  .bottombar {
    background-color: rgba(white, 0.95);
  }
  .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .menu-primary,
    .menu-secondary,
    .social,
    .menu-other {
      display: flex;
      align-items: center;

      a,
      p,
      li,
      span {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    } // menus

    .menu-primary,
    .menu-secondary,
    .menu-other {
      justify-content: flex-end;
      .menu-search {
        margin-left: 1em;
        @include flex-width(35px);
        border-left: 1px solid white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          color: white;
          font-size: 1.3em;
          font-weight: 400;
        }
      }
    } //menu-primary & menu-seconcondary

    .menu-primary {
      position: relative;
      padding: 0;
      margin: 0;
      li {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 2em;
        padding-bottom: 2em;
        cursor: pointer;
        a {
          // display: table-cell;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          vertical-align: middle;
          padding: 0;
          margin-top: 0.9em;
          font-size: 1.1em;
          font-family: $font;
          color: #a1a1a1;
          font-weight: 400;
          font-style: normal;
          letter-spacing: 0.2px;
          transition: 0.5s all ease-in-out;
        }
        &:last-child {
          ul.nolist {
            display: flex;
            flex-wrap: wrap;
            li {
              width: 50%;
            }
          }
        }
        @media (min-width: 1000px) {
          margin-left: 1em;
        }
        @media (min-width: 1170px) {
          margin-left: 3em;
        }
        .active a {
          color: $primary !important;
        }
      }
      a {
        &:hover,
        &:focus {
          color: $primary;
        }
      } //a
      li.active a.toptitle {
        color: $primary !important;
      }
    } //menu-primary

    .menu-secondary {
      li {
        margin-left: 1.5em;
      }
    } //menu-contact & menu-secondary
  } // container

  @media (max-width: $screen-sm) {
    display: none;
  }

  .dropdown-position {
    position: relative;
  }

  .dropdown {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    @include flex-width(100%);
    height: 250px;
    overflow: hidden;

    p {
      font-size: 12px;
    }

    .dropdown-exit {
      cursor: pointer;
      margin-top: 1.5em;
      font-weight: 100;
    }
    .section-split {
      margin-top: 1.5em;
      border-top: 1px solid rgba($secondary, 0.35);
      padding-top: 1.5em;
      @include flex-width(100%);
      .split-30 {
        border-right: 1px solid rgba($secondary, 0.35);
        .dropdown-link {
          margin-bottom: 0.5em;
          a {
            font-size: 14px;
            font-weight: 100;
            text-transform: uppercase;
          }
        }
        .dropdown-link-header a {
          font-size: 14px;
          font-weight: 500;
        }
        a {
          display: block;
          text-align: right;
          @include flex-width(100%);
          &:first-child {
            font-weight: 500;
          }
        }
      }
      .split-20 {
        text-align: right;
        .button {
          margin: 0;
        }
      }
      .split-50 {
        h1,
        h2,
        h3,
        h4,
        h5 {
          font-size: 20px;
          margin-top: 0;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
  .flexbox {
    .flexone {
      color: white;
      position: relative;
      text-transform: unset;
      letter-spacing: unset;
      font-size: unset;
      width: max-content;
      border-bottom: 1px solid $gray-light;
      h3 {
        color: $gray-darker;
        text-align: left;
        font-weight: bold;
        letter-spacing: 0;
        font-size: 1.6em;
        margin: 0;
        .shoplink {
          display: inline-block;
          font-size: 0.7em;
          color: inherit;
          margin: 0 1em;
          &:hover {
            color: $primary;
          }
        }
      }
      p {
        color: $gray-darker;
        letter-spacing: 0;
        font-weight: 100;
        font-family: $font;
        text-transform: none;
        font-size: 1.25em;
        margin: 0.8em 0;
      }
    }
    .flextwo {
      //margin-left: 1.5%;
      border-left: 1px solid white;
      font-size: 13px;
      text-align: left;
      position: relative;
      ul {
        width: 100%;
        padding-top: 0;
      }
      li {
        margin-left: 0;
        line-height: 2em;
      }
      ul > li > a {
        text-transform: uppercase;
        font-size: 1.15em;
        font-weight: 500;
        color: white;
        text-decoration: none;
        outline: none;
        padding: 0 0 0 0;
        transition: all 300ms ease;

        &:hover,
        &:focus {
          color: $secondary;
          text-decoration: none;
        }
      }
      ul div ul {
        text-transform: uppercase;
        width: 100%;
        padding: 10px 0 0;
      }
      .categories_section {
        justify-content: flex-start;
        .category-box {
          flex: 0 0 24%;
          margin-top: 3.5em;
        }
        .category-title {
          color: $gray-darker;
          font-size: 1em;
          letter-spacing: 0;
          font-weight: 700;
        }
        .sub-links {
          color: $gray-darker;
          letter-spacing: 0;
          font-weight: 400;
          font-family: $font;
          text-transform: none;
          font-size: 1.05em;
          display: block;
          margin: 0.6em 0 0 0;
          &:hover {
            color: $primary-dark;
          }
        }
      }
    }
  }
  ul > li > a {
    display: block;
    padding: 60px 0;
    color: #000;
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    &:hover,
    &:focus {
      color: $secondary;
      text-decoration: none;
    }
  }

  ul div ul {
    text-transform: uppercase;
    padding: 10px 0 0;
  }

  ul li:hover > ul,
  ul li:focus-within > ul,
  ul li ul:hover,
  ul li ul:focus {
    visibility: visible;
    opacity: 1;
    display: block;
  }

  ul li ul li {
    clear: both;
    width: 100%;
  }
  li .container-dropdown {
    display: block;
    position: absolute;
    margin-right: -7px;
    left: 0em;
    opacity: 1;
    // transform: translate(-50%, 0%);
    // top: 100%;
    top: -100vh;
    z-index: -1;
    background: #fbfbfb;
    border: 1px solid $gray-light;
    border-top: none;
    width: 65vw;
    padding: 4em 2em;
    transition: all 1.2s ease-in-out;
    &#rentals-dropdown {
      width: 80vw;
      .category-box {
        flex: 0 0 22%;
      }
      @media (min-width: $screen-lg) {
        width: 90em;
        .category-box {
          flex: 0 0 13%;
        }
      }
    }
    .title {
      color: black;
      font-size: 1.2em;
      padding-bottom: 1em;
    }
    .subtitle {
      color: black;
      text-transform: initial;
      font-size: 0.9em;
      font-weight: lighter;
    }
    .nolistevent {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
      }
    }
    .sub-pages {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      margin-top: -1em;
      padding: 0 1em 0 0;
      .dropdown-link {
        text-transform: uppercase;
        font-family: $font;
        font-size: 0.8em;
        width: 50%;
        padding-left: 1em;
        // margin-right: 0.2%;
        line-height: 3.5em;
        a {
          width: 100%;
          color: black;
          font-size: 1em;
          &:hover {
            color: black;
          }
        }
        &:hover {
          border: 0.2px solid rgb(224, 208, 208);
          // padding: 0 0.2em;
          opacity: 0.8;
          transition: opacity 0.5s ease-in-out;
          cursor: pointer;
        }
      }
    }
  }
  li .container-dropdown a.overview {
    width: 44px;
    padding-top: 91px;
    position: absolute;
    top: 50%;
    margin-top: -46px;
    left: 0;
    z-index: 10;
  }
  li .container-dropdown a.overview:hover {
    background-position: 0 0;
  }
  .ie8 li div {
    margin-top: 1px;
  }
  li:first-child .container-dropdown,
  li:first-child + li .container-dropdown {
    right: auto;
  }
  li .container-dropdown:after {
    position: absolute;
    bottom: -29px;
    height: 22px;
    left: 0;
    right: 0;
    transition: all 1.2s ease-in-out;
    content: "";
  }
  li:hover .container-dropdown {
    opacity: 1;
    transition: all 1.2s ease-in-out;
    // transform: translate(-50%, 0%);
    top: 100%;
  }
  li .container-dropdown .quote {
    display: block;
    position: absolute;
    color: #fff;
    font-family: $font;
    top: 0.4em;
    font-size: 1.5em;
    text-align: center;
    left: 0;
    right: 0;
  }
}

.menu-utility {
  li {
    font-size: 1.5em;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    span {
      width: 1em;
      height: 1em;
      display: block;
      position: relative;
    }

    i {
      display: block;
      transition: all 300ms ease;
    }

    &.menu-open {
      transition: all 300ms ease;

      span {
        padding-top: 0.075em;
      }

      i {
        height: 0.083333333em;
        width: 1em;
        background: $gray-light;
        margin: 0 0 0.3em;
      }

      &:hover {
        i {
          background: $primary;
        }
      }
    } // .menu-open

    &.menu-search {
      i:first-of-type {
        @include absolute(0, auto, auto, 0);
        width: 0.875em;
        height: 0.875em;
        border: 0.083333333em solid $gray-light;
        border-radius: 50%;
      }
      i:last-of-type {
        @include absolute(auto, 0, 0, auto);
        width: 0.35em;
        height: 0.083333333em;
        background: $gray-light;
        transform: rotate(50deg) translate3d(-0.1em, -0.00125em, 0);
      }

      &:hover {
        i:first-of-type {
          border-color: $primary;
        }
        i:last-of-type {
          background: $primary;
        }
      }
    }
  } // li
} // .menu-utility

@media screen {
  .knockout .menu-utility {
    li {
      &.menu-open {
        transition: all 300ms ease;

        span {
          padding-top: 0.075em;
        }

        i {
          background: rgba(white, 0.7);
        }

        &:hover {
          i {
            background: white;
          }
        }
      } // .menu-open

      &.menu-search {
        i:first-of-type {
          border-color: rgba(white, 0.7);
        }
        i:last-of-type {
          background: rgba(white, 0.7);
        }

        &:hover {
          i:first-of-type {
            border-color: white;
          }
          i:last-of-type {
            background: white;
          }
        }
      }
    } // li
  } // .knockout .menu-utility
} // screen

.body-scrolled {
  .menu-fixed-slide {
    top: 0;
    transition: 0.8s all ease-in-out;
  }
  .menu-fixed {
    transition: 0.8s all ease-in-out;
    @media (max-width: $screen-sm) {
      top: 0;
      img {
        height: 3em;
        transition: 0.8s all ease-in-out;
      }
      .menu-utility {
        padding: 1.8em 0;
      }
    }
    @media (min-width: $screen-md) {
      top: -100vh;
      img {
        height: 4em;
        transition: 0.8s all ease-in-out;
      }
      .menu-utility {
        padding: 1.8em 0;
        transition: 0.8s all ease-in-out;
      }
    }
    @media (min-width: $screen-sm) {
      img {
        height: 4em !important;
        transition: 0.8s all ease-in-out;
      }
      .menu-utility {
        padding: 1.8em 0;
        transition: 0.8s all ease-in-out;
      }
    }
  }
  .wrapper {
    padding-top: 7em;
  }
} // .body-is-scrolled

.menu-off-canvas-active {
  // body class to active off-canvas menu
  overflow: hidden;
  max-height: 100%;

  .wrapper,
  .menu-fixed {
    transform: translate3d(-$offCanvasWidth, 0, 0);
  }
  .menu-off-canvas {
    transform: translate3d(0, 0, 0);
    z-index: 10001;
  }

  .menu-utility {
    li {
      &.menu-open {
        i {
          &:nth-child(2) {
            background: none;
            transform: rotate(270deg);
          }

          &:nth-child(1) {
            transform: rotate(135deg) translate3d(0.25em, -0.25em, 0);
          }

          &:nth-child(3) {
            transform: rotate(-135deg) translate3d(0.3em, 0.25em, 0);
          }
        } // i
      } // &.menu-open
    } // li
  } //.menu-utility

  @media (min-width: $screen-md) {
    // disabled off canvas menu at the above breakpoint
    // change min-width if you need to disable before or
    // after this default breakpoint.
    overflow: visible;
    max-height: none;

    .wrapper,
    .menu-fixed {
      transform: translate3d(0, 0, 0);
    }
    .menu-off-canvas {
      transform: translate3d($offCanvasWidth, 0, 0);
      z-index: 10001;
    }
  }
} // .menu-off-canvas-active

.menu-off-canvas-active.left {
  .wrapper,
  .menu-fixed {
    transform: translate3d($offCanvasWidth, 0, 0);
  }
  .menu-off-canvas.left {
    transform: none;
  }
} // .menu-off-canvas-active

.banner {
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid rgba(black, 0.1);
  .banner-image {
    height: 100%;
  }

  .slick-dots {
    padding-bottom: 2em;
    li {
      border-color: white;
      font-size: 0.7em;
      background-color: transparent;

      &.slick-active {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
  .slick-arrow {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: auto;
    position: absolute;
    left: 3%;
    top: 50%;
    width: 50px;
    height: 50px;
    background: $primary;
    border-radius: 50%;
    transform: translate(0, -50%);
    z-index: 1;
    &.slick-next {
      right: 3%;
      left: auto;
    }
    i {
      padding: 0;
      color: white;
      font-weight: 500;
      font-size: 2em;
    }
    &.slick-disabled {
      cursor: not-allowed;
      i {
        color: #616060;
      }
    }
    @media (min-width: $screen-lg) {
      left: 5%;
      &.slick-next {
        right: 5%;
      }
    }
  }
  .banner-image {
    overflow: hidden;
    display: block;
    img {
      width: 100%;
      display: block;
    }
  }
  article {
    width: 100%;
    background-size: cover;
    background-position: center;
    height: 100%;
  }
  .button {
    margin: 1.5em 0 0;
  }
  .blurb {
    .section-split {
      flex-wrap: wrap;
      .split-col {
        flex: 1 1 47%;
        margin-right: 3%;
      }
      .split-logo {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .split-info {
        padding: 2em 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .overlay {
    padding: 2em 0 0 0;
  }
  @media screen and (min-width: $screen-sm) {
    article {
      height: 30em;
    }
    border: none;
    .banner-image {
      display: none;
    }
    &:not(.button) {
      text-shadow: 0 0.125em 0.25em rgba(black, 0.3);
    }

    h1 {
      color: white;
      font-size: 1.75em;
    }
    p.subtitle {
      color: white;
      line-height: 22px;
      font-size: 16px;
      font-weight: 300;
    }

    .bg-fade {
      @include absolute(0);
      opacity: 0.6;
      z-index: 2;
    }

    .overlay {
      z-index: 3;
      padding: 0;
      display: flex;
      align-items: center;
      height: 100%;
      background-color: rgba(black, 0.55);
    }

    .blurb {
      flex: 1 1 100%;
      margin: auto 10%;
      max-width: none;
      text-align: left;
    }
  }
  @media (min-width: $screen-md) {
    .blurb {
      margin: auto 15%;
    }
  }
} // .banner

.probox .proinput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  form {
    width: 100%;
  }
}
